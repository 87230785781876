import React from 'react';
import './FormLegend.css';

function FormLegend(props) {
    const { onToggleHelp } = props;

    return (
        <div className="FormLegend">
            <div className="legend z-depth-3">
                <div className="header"><div>Annotation Actions</div>
                    <button className="close btn-flat gray-text text-darken-2 waves-effect waves-light"
                        onClick={onToggleHelp}
                        >
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="row">click on token: annotate token (letters and digits only)</div>
                <div className="row">Alt-click on token: annotate token (letters, digits &amp; punctuation)</div>
                <div className="row">mouse-select &amp; click: annotate selected text</div>
                <div className="row">hold Ctrl (Windows) or ⌘ (Mac): append annotation on click or Alt-click</div>
                <div className="row">double-click on field: enter edit mode</div>
                <div className="row">Esc: leave edit mode (alternatively, click outside of focus field to leave edit mode)</div>
                <div className="row">1-9: add 1-9 nested fields (when focus is on a nested list field)</div>
                <div className="row">-: delete last nested field (when focus is on a nested list field)</div>
                <div className="row">x or delete or backspace: delete field content</div>
                <div className="row">a/A: add record after(a) / before(A) record with focus field</div>
                <div className="row"><img alt="Delete Record" src="images/delete_icon_red_43x55.png" />: delete record</div>
                <div className="row">click on field: move focus to field</div>
                <div className="row">Tab/shift-Tab: move to next/previous field</div>
                <div className="row">hover over filled-in field: highlight extracted text</div>
            </div>
        </div>
    );
}

export default FormLegend;
