import React, { useEffect, useState } from 'react';
import { FIELD_TYPE_SINGLE, HINT_TYPE_BREAK } from '../Constants.js';
import FormControls from './FormControls.js';
import FormField from './FormField.js';
import FormLabels from './FormLabels.js';
import FormLegend from './FormLegend.js';
import FormRecordDelete from './FormRecordDelete.js';
import GreenQQControls from '../GreenQQ/GreenQQControls.js';
import {
    cloneObject, copyTextToClipboard, getIndent,
    hasClass, removeKeys, syntaxHighlight, uniqueId,
} from '../App/Utility.js';
import './FormWidget.css';
import M from 'materialize-css/dist/js/materialize.min.js';

function FormWidget(props) {
    const {
        annotations, currentFieldId, currentFieldName, deleteHover,
        formJson, mergedPersonaName,
        onCancel, onDeleteHover, onDeleteRecord, onDone, onHighlightHover,
        onSave, onValueChanged, personaName, setCurrentField,
    } = props;
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        function init() {
            M.Modal.init(document.querySelectorAll('.modal'), {});
        }

        if (document.attachEvent ?
            document.readyState === "complete" :
            document.readyState !== "loading") {
            init();
        } else {
            document.addEventListener('DOMContentLoaded', init);
        }
        document.querySelector("body").style.overflow = "hidden";
    }, []);

    function formatAltField(field) {
        if (field.type === FIELD_TYPE_SINGLE) {
            if (field.userText !== "") {
                return <div className="AltField" key={uniqueId()}><span
                            className="AltLabel">{field.label + ": "}</span> {field.userText}</div>;
            }
        } else {
            let fieldRecords = [];

            field.records.forEach((record) => {
                let nestedFields = [];

                record.forEach((subfield) => {
                    const altField = formatAltField(subfield);

                    if (altField) {
                        nestedFields.push(altField);
                    }
                });

                if (nestedFields.length > 0) {
                    fieldRecords.push(<div className="AltMultipleField" key={record.id + "alt"}>{nestedFields}</div>);
                }
            });

            if (fieldRecords.length > 0) {
                return <div className="AltFieldDisplay" key={field.id + "alt"}>{fieldRecords}</div>;
            }
        }

        return null;
    }

    function renderField(field, index, offset) {
        if (field.type === FIELD_TYPE_SINGLE) {
            const colorIndex = field.colorIndex;

            return { field:
                        <FormField
                            colorIndex={colorIndex}
                            currentFieldId={currentFieldId}
                            fieldJson={field}
                            key={field.id}
                            index={index}
                            onValueChanged={onValueChanged}
                            setCurrentField={setCurrentField}
                            />,
                     offset };
        } else {
            let formRecords = [];
            let className = "FieldMultipleGroup offset" + offset;

            offset += 1;

            field.records.forEach((record) => {
                let nestedFields = [];

                record.forEach((subfield) => {
                    const rendered = renderField(subfield, index, offset);

                    nestedFields.push(rendered.field);
                    // NOTE: ignoring the offset return value here works fine for
                    // doubly nested groups, but may not work for triply nested
                    // groups if we ever get them
                });

                formRecords.push(<div className="FieldNestedGroup" key={record.id}>{nestedFields}</div>);
            });

            return { field: <div className={className} key={field.id}>{formRecords}</div>,
                     offset};
        }
    }

    function copyJsonToClipboard() {
        let json = cloneObject(annotations);

        json.records.forEach((record) => {
            removeKeys(record);
        });

        const text = JSON.stringify(json);

        copyTextToClipboard(text);
    }

    function idForTarget(e) {
        let target = e.target;

        while (target && !hasClass(target, "FormWrapper")) {
            target = target.parentNode;
        }

        if (target) {
            let id = target.getAttribute("id");

            if (id && id.length > 1) {
                return id.slice(1);
            }
        }
    }

    function onToggleHelp() {
        setShowHelp(!showHelp);
    }

    function startHovering(e) {
        let id = idForTarget(e);

        if (id) {
            onHighlightHover(true, id);
        }
    }

    function stopHovering(e) {
        let id = idForTarget(e);

        if (id) {
            onHighlightHover(false, id);
        }
    }

    let recordIndex = 0;
    let records = [];
    let persona = `${mergedPersonaName}${personaName}`;

    annotations.records.forEach((record) => {
        let altFields = [];
        let fields = [];
        let recordId = "r" + record.id;
        let offset = 0;
        let wrapperClass = "FormWrapper";

        if (deleteHover === record.id) {
            wrapperClass += " hoverDelete";
        }

        record.forEach((field) => {
            if (field.hint) {
                if (field.hint.includes(HINT_TYPE_BREAK)) {
                    fields.push(<br className="recordWrap" key={uniqueId()} />);
                }

                let indent = getIndent(field.hint);

                while (indent > 0) {
                    fields.push(<div className="recordSpacer" key={uniqueId()} />);
                    indent -= 1;
                }
            }

            const rendered = renderField(field, recordIndex, offset);

            fields.push(rendered.field);
            offset = rendered.offset;

            if (persona !== "" && persona !== "GreenQQ") {
                const altField = formatAltField(field);

                if (altField) {
                    altFields.push(altField);
                }
            }
        });

        records.push(
            <div
                className={wrapperClass}
                id={recordId}
                key={recordId}
                onMouseEnter={startHovering}
                onMouseLeave={stopHovering}
            >
                <div className="FormContainer">
                    {fields}
                </div>
                <FormRecordDelete
                    recordId={recordId}
                    recordIndex={recordIndex}
                    personaName={persona}
                    onDeleteRecord={onDeleteRecord}
                    onHover={onDeleteHover}
                />
            </div>
        );

        if (persona !== "" && altFields.length > 0) {
            records.push(
                <div className="RecordAlt" key={recordId + "alt"}>
                    {altFields}
                </div>
            );
        }

        recordIndex += 1;
    });

    return (
        <div className="FormWidgetBody">
            <FormLabels
                currentFieldName={currentFieldName}
                formJson={formJson}
                onDone={onDone}
                onSave={onSave}
                onToggleHelp={onToggleHelp}
                personaName={persona}
            />
            <div className="FormRecords">
                {records}
            </div>
            {
                (persona === "GreenQQ")
                    ?   <GreenQQControls />
                    :   <FormControls
                            annotations={annotations}
                            onDone={onDone}
                            onSave={onSave}
                            onToggleHelp={onToggleHelp}
                        />
            }
            {
                showHelp ? <FormLegend onToggleHelp={onToggleHelp} /> : null
            }
            <div id="jsonModal" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <h4>Annotation JSON</h4>
                    <pre dangerouslySetInnerHTML={syntaxHighlight(annotations)} />
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-close waves-effect waves-blue btn-flat"
                        onClick={copyJsonToClipboard}
                    >Copy to Clipboard</button>
                    <button
                        className="modal-close waves-effect waves-blue btn-flat"
                    >Done</button>
                </div>
            </div>
            <div id="confirmModal" className="modal">
                <div className="modal-content">
                    <h4>Please Confirm</h4>
                    <p>Are you sure you want to exit without saving changes?</p>
                </div>
                <div className="modal-footer">
                    <button className="waves-effect waves-red red btn" onClick={onCancel}>Yes</button> &nbsp;
                    <button className="modal-close waves-effect waves-green green btn">No</button>
                </div>
            </div>
        </div>
    );
}

export default FormWidget;
