import React, { useEffect } from 'react';
import { useConfig } from '../App/Config';
import { BUTTON_CLASS } from '../Constants';

function GreenQQForm(props) {
    const { generateBooksSelect, receiveFocus } = props;
    const { config, onGreenQQ, updateConfig } = useConfig();

    let bookSelect;
    let firstPageInput;
    let lastPageInput;
    let skipBottomInput;
    let skipTopInput;

    let books = generateBooksSelect(handleBookSelection);
    let buttonClass = BUTTON_CLASS;

    if (config.greenQQBook === "") {
        buttonClass += " disabled";
    }

    useEffect(() => {
        firstPageInput.focus();
        firstPageInput.blur();
        lastPageInput.focus();
        lastPageInput.blur();
        skipBottomInput.focus();
        skipBottomInput.blur();
        skipTopInput.focus();
        skipTopInput.blur();
    }, [firstPageInput, lastPageInput, skipBottomInput, skipTopInput]);

    function handleBookSelection(event) {
        event.preventDefault();

        const selection = bookSelect.options[bookSelect.selectedIndex];
        let greenQQBookObject = config.booksList[selection.value];

        if (selection.value === greenQQBookObject.shortTitle) {
            let firstPage = config.firstPage;
            let lastPage = config.lastPage;
            const greenQQMaxPage = greenQQBookObject.maxPage;

            if (firstPage > greenQQBookObject.maxPage) {
                firstPage = greenQQBookObject.maxPage;
            }

            if (lastPage > greenQQBookObject.maxPage) {
                lastPage = greenQQBookObject.maxPage;
            }

            updateConfig({
                greenQQBook: greenQQBookObject.shortTitle,
                greenQQBookObject,
                firstPage,
                lastPage,
                greenQQMaxPage
            });
        }
    }

    function handleFirstPageChange(event) {
        event.preventDefault();

        if (event.target.value === "") {
            updateConfig({ firstPage: "" });
            return;
        }

        let firstPage = Number(event.target.value);

        if (firstPage < 1 || isNaN(firstPage)) {
            firstPage = 1;
        } else if (firstPage > config.greenQQMaxPage) {
            firstPage = config.greenQQMaxPage;
        }

        updateConfig({ firstPage });
    }

    function handleLastPageChange(event) {
        event.preventDefault();

        if (event.target.value === "") {
            updateConfig({ lastPage: "" });
            return;
        }

        let lastPage = Number(event.target.value);

        if (lastPage < 1 || isNaN(lastPage)) {
            lastPage = 1;
        } else if (lastPage > config.greenQQMaxPage) {
            lastPage = config.greenQQMaxPage;
        }

        updateConfig({ lastPage });
    }

    function handleSkipBottomChange(event) {
        if (event.target.value === "") {
            updateConfig({ skipBottom: "" });
            return;
        }

        let skipBottom = Number(event.target.value);

        if (skipBottom < 0 || isNaN(skipBottom)) {
            skipBottom = 0;
        }

        updateConfig({ skipBottom });
    }

    function handleSkipTopChange(event) {
        if (event.target.value === "") {
            updateConfig({ skipTop: "" });
            return;
        }

        let skipTop = Number(event.target.value);

        if (skipTop < 0 || isNaN(skipTop)) {
            skipTop = 0;
        }

        updateConfig({ skipTop });
    }

    return (
        <div className="container GreenQQMenu">
            <form className="GreenQQForm" onSubmit={(e) => { e.preventDefault(); onGreenQQ(); }}>
                <div className="row">
                    <h5 className="col s12">Configure GreenQQ parameters:</h5>
                </div>
                <div className="row">
                    <div className="input-field col s5">
                        <select
                            className="browser-default"
                            id="GreenQQBookSelect"
                            ref={(element) => { bookSelect = element; }}
                            onChange={handleBookSelection}
                            value={config.greenQQBook === "" ? config.greenQQBook : config.greenQQBook.shortTitle}
                        >
                            {books}
                        </select>
                    </div>
                    <div className="col s2 offset-s8">
                        <button
                            className={buttonClass}
                            type="submit"
                            name="action"
                            onClick={(e) => { e.preventDefault(); onGreenQQ(); }}
                        >Use GreenQQ <i className="material-icons right">send</i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s2">
                        <input
                            type="text"
                            id="greenQQFirstPage"
                            ref={(input) => { firstPageInput = input; }}
                            value={config.firstPage}
                            onChange={handleFirstPageChange}
                            onFocus={receiveFocus}
                        />
                        <label htmlFor="greenQQFirstPage">First Page</label>
                        <span className="helper-text">Max page {config.greenQQMaxPage}</span>
                    </div>
                    <div className="input-field col s2">
                        <input
                            type="text"
                            id="greenQQLastPage"
                            ref={(input) => { lastPageInput = input; }}
                            value={config.lastPage}
                            onChange={handleLastPageChange}
                            onFocus={receiveFocus}
                        />
                        <label htmlFor="greenQQLastPage">Last Page</label>
                        <span className="helper-text">Max page {config.greenQQMaxPage}</span>
                    </div>
                    <div className="input-field col s2">
                        <input
                            type="text"
                            id="greenQQSkipTop"
                            ref={(input) => { skipTopInput = input; }}
                            value={config.skipTop}
                            onChange={handleSkipTopChange}
                            onFocus={receiveFocus}
                        />
                        <label htmlFor="greenQQSkipTop">Skip Top</label>
                    </div>
                    <div className="input-field col s2">
                        <input
                            type="text"
                            id="greenQQSkipBottom"
                            ref={(input) => { skipBottomInput = input; }}
                            value={config.skipBottom}
                            onChange={handleSkipBottomChange}
                            onFocus={receiveFocus}
                        />
                        <label htmlFor="greenQQSkipBottom">Skip Bottom</label>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default GreenQQForm;