import React, { Component } from 'react';
import './GreenQQControls.css';

class GreenQQControls extends Component {
    render() {
        return (
            <div className="GreenQQControls z-depth-2">
                <button
                    className="btn waves-effect waves-light blue"
                    onClick={this.props.onSave}
                    >Save <i className="material-icons right">save</i>
                </button> &nbsp;
                <button
                    className="btn waves-effect waves-light blue modal-trigger"
                    onClick={this.props.onSelectAll}
                    >Select All <i className="material-icons right">select_all</i>
                </button> &nbsp;
                <button
                    className="btn waves-effect waves-light blue modal-trigger"
                    onClick={this.props.onDeselectAll}
                    >Deselect All <i className="material-icons right">ballot</i>
                </button> &nbsp;
                <button
                    className="btn waves-effect waves-light blue"
                    onClick={this.props.onExecuteSelected}
                    >Execute Selected <i className="material-icons right">send</i>
                </button> &nbsp; &nbsp; &nbsp; &nbsp; 
                <button
                    className="btn waves-effect waves-light red lighten-2 CancelButton modal-trigger"
                    data-target="confirmModal"
                    >Close <i className="material-icons right">close</i>
                </button>
                <img alt="Resize handle" src="images/resize_handle_18x18.png" />
            </div>
        );
    }
}

export default GreenQQControls;
