import React, { useCallback, useEffect, useRef, useState } from 'react';
import { COLOR_COUNT, FIELD_TYPE_SINGLE, FORM_WIDTH_FACTOR_NORMAL,
    FORM_WIDTH_FACTOR_WIDE, WIDE_FORMS, ZOOM_DELTA, ZOOM_FACTOR
} from '../Constants';
import AnnotatedForm from './AnnotatedForm';
import FormWidget from './FormWidget';
import HeaderWidget from './HeaderWidget';
import KeyboardListener from './KeyboardListener';
import PageWidget from './PageWidget';
import { annotationUriForPage, cloneObject, each, isTextInputActive, usePrevious } from '../App/Utility';
import useFetchAnnotations from './FetchAnnotations';
import useResizeObserver from '../App/ResizeObserver';
import { useConfig } from '../App/Config';
import './Comet.css';

function clone(form) {
    if (form) {
        const formClone = new AnnotatedForm(form);

        formClone.annotations = cloneObject(form.annotations);
        formClone.rebuildCache();

        return formClone;
    } else {
        return null;
    }
}

function Comet(props) {
    const { config, onCancel, onDone, onSave } = useConfig();

    const {
        book, bookObject, formName, formVersion, maxPage, mergedPersonaBookObject,
        mergedPersonaName, pageNumber, personaBookObject, personaName
    } = config;

    const [deleteHover, setDeleteHover] = useState(null);
    const [displayPageNumber, setDisplayPageNumber] = useState(pageNumber);
    const [highlightHover, setHighlightHover] = useState(null);
    const [highlightRanges, setHighlightRanges] = useState(null);
    const [isAltPressed, setIsAltPressed] = useState(false);
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [pageDisplayMode, setPageDisplayMode] = useState(0);
    const [zoomFactor, setZoomFactor] = useState(ZOOM_FACTOR);

    const { annotatedForm, formJson, isLoading, isError, isLoaded, setAnnotatedForm }
        = useFetchAnnotations(bookObject, formName, formVersion,
            maxPage, mergedPersonaBookObject, mergedPersonaName, pageNumber,
            personaBookObject, personaName);

    const prevAnnotatedForm = usePrevious(annotatedForm);

    const autoscrollToCurrent = useCallback(function () {
        let fieldElement = document.querySelector("#f" + annotatedForm.currentFieldId);

        if (fieldElement && !visible(fieldElement)) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    }, [annotatedForm.currentFieldId]);

    const formWidgetRef = useRef(null);

    useResizeObserver({
        callback: () => { resizePanels(); },
        element: [formWidgetRef]
    });

    const resizePanels = useCallback(function () {
        let width = window.innerWidth;
        let formWidthPx = document.querySelector(".FormWidget").style.width;
        let formWidth = Number(formWidthPx.slice(0, formWidthPx.length - 2));

        each(".FormLabels.showFixed", function (element) {
            element.style.width = formWidth + "px";
        });

        each(".PageWidget", function (element) {
            element.style.width = (width - formWidth) + "px";
        });
    }, []);

    const sizePanels = useCallback(function () {
        let resizeFactor = WIDE_FORMS.includes(formName)
            ? FORM_WIDTH_FACTOR_WIDE
            : FORM_WIDTH_FACTOR_NORMAL;
        let height = window.innerHeight;
        let width = window.innerWidth;
        let halfWidth = Math.ceil(width * resizeFactor);
        let usedWidth = 0;

        each(".HeaderWidget", (element) => {
            height -= element.offsetHeight + 4;
        });

        each(".FormLabels.showFixed", function (element) {
            element.style.width = halfWidth + "px";
        });

        each(".FormWidget, .PageWidget", function (element) {
            element.style.height = height + "px";
            element.style.width = halfWidth + "px";

            usedWidth += halfWidth;
            halfWidth = width - usedWidth;
        });
    }, [formName]);

    useEffect(() => {
        // Listen for window resize events
        window.onresize = sizePanels;
        setTimeout(sizePanels, 350);
    }, [sizePanels]);

    useEffect(() => {
        if (prevAnnotatedForm && annotatedForm && prevAnnotatedForm.currentFieldId !== annotatedForm.currentFieldId) {
            autoscrollToCurrent();
        }

    }, [annotatedForm, autoscrollToCurrent, prevAnnotatedForm]);

    function annotationForSelection(selection) {
        return {
            originalText: selection.originalText,
            startOffset: Number(selection.startOffset),
            endOffset: Number(selection.endOffset),
            resource: {
                mime: "application/pdf",
                uri: annotationUriForPage(book, maxPage, displayPageNumber, "pdf"),
            },
            text: selection.text,
        };
    }

    function gatherHighlightRanges(ranges, fieldArray, colorIndex) {
        let i = 0;

        while (i < fieldArray.length) {
            if (fieldArray[i].type === FIELD_TYPE_SINGLE) {
                if (fieldArray[i].annotationComponents &&
                    fieldArray[i].annotationComponents.length > 0) {

                    gatherHighlightRangesForField(ranges, fieldArray[i], colorIndex);
                    fieldArray[i].colorIndex = colorIndex;
                    colorIndex += 1;
                    colorIndex = colorIndex % COLOR_COUNT;
                } else {
                    delete fieldArray[i].colorIndex;
                }
            } else {
                let j = 0;

                while (j < fieldArray[i].records.length) {
                    colorIndex = gatherHighlightRanges(ranges, fieldArray[i].records[j], colorIndex);
                    j += 1;
                }
            }

            i += 1;
        }

        return colorIndex;
    }

    function gatherHighlightRangesForField(ranges, field, colorIndex) {
        const pageUri = annotationUriForPage(book, maxPage, displayPageNumber, "pdf");
        const altPageUri = pageUri.replace("https", "http");

        field.annotationComponents.forEach((component) => {
            if (component.resource.uri === pageUri || component.resource.uri === altPageUri) {
                ranges.push([Number(component.startOffset),
                Number(component.endOffset) + 1,
                    colorIndex]);
            }
        });
    }

    function highlightRangesForRecord(form, recordId) {
        const records = form.annotations.records;
        let ranges = [];

        records.forEach((record) => {
            if (record.id === recordId) {
                gatherHighlightRanges(ranges, record, 0);
            }
        });

        return ranges;
    }

    function onAddNestedRecords(count) {
        const formClone = clone(annotatedForm);
        const addedCount = formClone.addNestedRecords(count);

        if (addedCount > 0) {
            setAnnotatedForm(formClone);
        }
    }

    function onBlurActiveField() {
        if (isTextInputActive()) {
            document.activeElement.blur();
        }
    }

    function onDeleteAnnotation() {
        const formClone = clone(annotatedForm);

        if (formClone.deleteAnnotation()) {
            setAnnotatedForm(formClone);
        }
    }

    function onDeleteHover(isHovering, recordId) {
        setDeleteHover(isHovering ? recordId.slice(1) : null );
    }

    function onDeleteRecord(recordIndex) {
        const formClone = clone(annotatedForm);

        if (formClone.deleteRecord(recordIndex)) {
            setAnnotatedForm(formClone);
            setHighlightRanges([]);
        }
    }

    function onCometDone() {
        onDone(annotatedForm.annotations);
    }

    function onHighlightHover(isHovering, recordId) {
        const formClone = clone(annotatedForm);
        let highlights = [];

        if (isHovering) {
            removeColorIndexesForRecord(formClone);
            highlights = highlightRangesForRecord(formClone, recordId);
        } else {
            removeColorIndexesForRecord(formClone, recordId);
        }

        setAnnotatedForm(formClone);
        setDeleteHover(null);
        setHighlightHover(isHovering ? recordId : null);
        setHighlightRanges(highlights);
    }

    function onInsertNewRecord(offsetFromCurrent) {
        const formClone = clone(annotatedForm);

        if (formClone.insertNewRecord(offsetFromCurrent)) {
            setAnnotatedForm(formClone);
        }
    }

    function onMoveToNextField() {
        const formClone = clone(annotatedForm);

        formClone.moveToNextField();
        setAnnotatedForm(formClone);
    }

    function onMoveToPreviousField() {
        const formClone = clone(annotatedForm);

        formClone.moveToPreviousField();
        setAnnotatedForm(formClone);
    }

    function onCometPageNext() {
        if (displayPageNumber < maxPage) {
            setDisplayPageNumber(Number(displayPageNumber) + 1);
        }

        setHighlightRanges([]);
    }

    function onCometPagePrevious() {
        if (displayPageNumber > 1) {
            setDisplayPageNumber(Number(displayPageNumber) - 1);
        }

        setHighlightRanges([]);
    }

    function onCometPageSelect(page) {
        let newPage = Math.floor(Number(page));

        if (newPage < 1 || isNaN(newPage)) {
            newPage = 1;
        }

        if (newPage > maxPage) {
            newPage = maxPage;
        }

        setDisplayPageNumber(newPage);
        setHighlightRanges([]);
    }

    function onRemoveLastNestedRecord() {
        const formClone = clone(annotatedForm);

        if (formClone.removeLastNestedRecord()) {
            setAnnotatedForm(formClone);
        }
    }

    function onCometSave() {
        onSave(annotatedForm.annotations);
    }

    function onSelection(selection) {
        const formClone = clone(annotatedForm);
        const annotation = annotationForSelection(selection);

        if (formClone.annotateField(annotation, isCtrlPressed)) {
            setAnnotatedForm(formClone);
        }
    }

    function onValueChanged(value) {
        const formClone = clone(annotatedForm);

        if (formClone.changeFieldValue(value)) {
            setAnnotatedForm(formClone);
        }
    }

    function onZoomIn() {
        setZoomFactor(zoomFactor + ZOOM_DELTA);
    }

    function onZoomOut() {
        setZoomFactor(zoomFactor - ZOOM_DELTA);
    }

    function pageSettings(mode) {
        setPageDisplayMode(mode);
    }

    function removeColorIndexes(fieldArray) {
        let i = 0;

        while (i < fieldArray.length) {
            if (fieldArray[i].type === FIELD_TYPE_SINGLE) {
                delete fieldArray[i].colorIndex;
            } else {
                fieldArray[i].records.forEach((nestedGroup) => {
                    removeColorIndexes(nestedGroup);
                });
            }

            i += 1;
        }
    }

    function removeColorIndexesForRecord(form, recordId) {
        const records = form.annotations.records;

        records.forEach((record) => {
            if (!recordId || record.id === recordId) {
                removeColorIndexes(record);
            }
        });
    }

    function setAltState(newState) {
        setIsAltPressed(newState);
    }

    function setCtrlState(newState) {
        setIsCtrlPressed(newState);
    }

    function setCurrentField(fieldId, fieldName) {
        const formClone = clone(annotatedForm);

        if (annotatedForm.currentFieldId !== fieldId) {
            onBlurActiveField();
            formClone.setCurrentField(fieldId, fieldName);

            setAnnotatedForm(formClone);
        }
    }

    function setShiftState(newState) {
        setIsShiftPressed(newState);
    }

    function visible(element) {
        let elementOffset = element.getBoundingClientRect();
        let recordsDiv = document.querySelector(".FormRecords");
        let recordsOffset = recordsDiv.getBoundingClientRect();

        return elementOffset.top >= recordsOffset.top &&
            elementOffset.top <= recordsOffset.bottom &&
            elementOffset.bottom >= recordsOffset.top &&
            elementOffset.bottom <= recordsOffset.bottom;
    }

    if (isError) {
        return <div>Error: Unable to load form or annotations</div>;
    } else if (!isLoaded || isLoading) {
        return <div>Loading...</div>;
    }

    if (annotatedForm.currentFieldName && !annotatedForm.currentFieldId) {
        const formClone = clone(annotatedForm);

        formClone.currentFieldName = null;

        setAnnotatedForm(formClone);
    }

    return (
        <div className="Comet">
            <KeyboardListener
                isAltPressed={isAltPressed}
                isCtrlPressed={isCtrlPressed}
                isKeyboardEnabled={true}
                isShiftPressed={isShiftPressed}
                onAddNestedRecords={onAddNestedRecords}
                onBlurActiveField={onBlurActiveField}
                onDeleteAnnotation={onDeleteAnnotation}
                onInsertNewRecord={onInsertNewRecord}
                onMoveToNextField={onMoveToNextField}
                onMoveToPreviousField={onMoveToPreviousField}
                onRemoveLastNestedRecord={onRemoveLastNestedRecord}
                setAltState={setAltState}
                setCtrlState={setCtrlState}
                setShiftState={setShiftState}
            />
            <HeaderWidget
                book={book}
                formName={formName}
                displayPageNumber={displayPageNumber}
                maxPage={maxPage}
                mergedPersonaName={mergedPersonaName}
                onPageNext={onCometPageNext}
                onPagePrevious={onCometPagePrevious}
                onPageSelect={onCometPageSelect}
                onPageSettings={pageSettings}
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
                pageDisplayMode={pageDisplayMode}
                pageNumber={pageNumber}
                personaName={personaName}
            />
            <div className="CometBody">
                <div className="FormWidget" ref={formWidgetRef} >
                    <FormWidget
                        annotations={annotatedForm.annotations}
                        currentFieldId={annotatedForm.currentFieldId}
                        currentFieldName={annotatedForm.currentFieldName}
                        deleteHover={deleteHover}
                        highlightHover={highlightHover}
                        formJson={formJson}
                        mergedPersonaName={mergedPersonaName}
                        onCancel={onCancel}
                        onDeleteHover={onDeleteHover}
                        onDeleteRecord={onDeleteRecord}
                        onDone={onCometDone}
                        onHighlightHover={onHighlightHover}
                        onSave={onCometSave}
                        onValueChanged={onValueChanged}
                        personaName={personaName}
                        setCurrentField={setCurrentField}
                        />
                </div>
                <PageWidget
                    book={book}
                    highlightRanges={highlightRanges}
                    isAltPressed={isAltPressed}
                    maxPage={maxPage}
                    onSelection={onSelection}
                    pageDisplayMode={pageDisplayMode}
                    pageNumber={displayPageNumber}
                    zoomFactor={zoomFactor}
                    />
            </div>
        </div>
    );
}

export { clone };
export default Comet;
