import React, { useEffect } from 'react';
import { FIELD_TYPE_SINGLE, HINT_TYPE_BREAK } from '../Constants.js';
import { camelCaseToSpaces, decodeHTMLEntities, each, isTextInputActive } from '../App/Utility.js';
import M from 'materialize-css/dist/js/materialize.min.js';

function FormField(props) {
    const {
        fieldJson, colorIndex, currentFieldId, index, onValueChanged, setCurrentField
    } = props;

    useEffect(() => {
        function init() {
            M.Modal.init(document.querySelectorAll('.modal'), {});
        }

        if (document.attachEvent ?
            document.readyState === "complete" :
            document.readyState !== "loading") {
            init();
        } else {
            document.addEventListener('DOMContentLoaded', init);
        }
    }, []);

    function buttonForComment(json, modalId) {
        if (json.comment && json.comment.length > 3) {
            let className = "messageButton btn-flat waves-effect waves-light modal-trigger";
            let comments = json.comment.split(/\|[eiw]:/).map(decodeHTMLEntities);
            let commentMarkup = { __html: comments.join("") };
            let src;

            switch (json.comment.slice(1, 2)) {
                case 'e':
                    className += " error";
                    src = "images/MessageError.svg";
                    break;
                case 'w':
                    className += " warn";
                    src = "images/MessageWarn.svg";
                    break;
                default:
                    className += " info";
                    src = "images/MessageInfo.svg";
                    break;
            }

            let button = <button
                className={className}
                data-target={modalId}><img src={src} alt="Show message" /></button>;
            let modal = <div id={modalId} className="modal message-dialog">
                <div className="modal-content">
                    <p dangerouslySetInnerHTML={commentMarkup} />
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-close waves-effect waves-blue btn-flat"
                    >Close</button>
                </div>
            </div>;

            return { button, modal };
        }
    }

    function clearEventDefaults(e) {
        e.cancelBubble = true;
        e.returnValue = false;

        if (e.preventDefault) {
            e.preventDefault();
        }

        if (e.stopPropagation) {
            e.stopPropagation();
        }
    }

    function onClick(e) {
        if (!isTextInputActive()) {
            clearEventDefaults(e);
        }

        setCurrentField(fieldJson.id, fieldJson.label);
    }

    function onDoubleClick(e) {
        clearEventDefaults(e);

        setCurrentField(fieldJson.id, fieldJson.label);

        each("#f" + fieldJson.id, function (element) {
            element.focus();
        });
    }

    function onMouseDown(e) {
        if (!isTextInputActive()) {
            if (e.preventDefault) {
                e.preventDefault();
            }
        }

        setCurrentField(fieldJson.id, fieldJson.label);
    }

    const id = "f" + fieldJson.id;
    const visibleLabel = camelCaseToSpaces(fieldJson.label);
    let fieldClass = "FormField input-field";

    if (fieldJson.type === FIELD_TYPE_SINGLE) {
        let messageButton = null;
        let messageModal = null;
        let modalId = "message" + fieldJson.id;

        if (currentFieldId === fieldJson.id) {
            fieldClass += " currentField";
        }

        let buttonModal = buttonForComment(fieldJson, modalId);

        if (colorIndex !== undefined) {
            fieldClass += " hover" + colorIndex;

            if (buttonModal) {
                messageButton = buttonModal.button;
            }
        }

        if (buttonModal) {
            messageModal = buttonModal.modal;
        }

        return(
            <div className={fieldClass}>
                <div className="FieldBorder">
                    <input
                        id={id}
                        onChange={(e) => onValueChanged(e.target.value)}
                        onClick={onClick}
                        onDoubleClick={onDoubleClick}
                        onMouseDown={onMouseDown}
                        placeholder={visibleLabel}
                        recordindex={index}
                        type="text"
                        value={fieldJson.userText}
                        />
                    {messageButton}
                </div>
                {messageModal}
            </div>
        );
    } else {
        if (fieldJson.fields) {
            let fields = [];

            fieldJson.fields.forEach((field) => {
                const fieldComponent = <FormField
                                            fieldJson={field}
                                            currentFieldId={currentFieldId}
                                            index={index}
                                            />

                if (field.hint && field.hint.includes(HINT_TYPE_BREAK)) {
                    fields.push(<br className="recordWrap" />);
                }

                fields.push(fieldComponent);
            });

            return(
                <div className={fieldClass}>
                    {fields}
                </div>
            );
        } else {
            let records = [];

            fieldJson.records.forEach((record) => {
                let fields = [];

                record.forEach((field) => {
                    const fieldComponent = <FormField
                                                fieldJson={field}
                                                currentFieldId={currentFieldId}
                                                index={index}
                                                />

                    if (field.hint && field.hint.includes(HINT_TYPE_BREAK)) {
                        fields.push(<br className="recordWrap" />);
                    }

                    fields.push(fieldComponent);
                });

                records.push(
                    <div className={fieldClass}>
                        {fields}
                    </div>
                );
            });

            return(
                <div className="FormMultipleGroup">
                    {records}
                </div>
            );
        }
    }
}

export default FormField;
