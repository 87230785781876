import { useEffect, useState } from 'react';
import { uriForBookFormPage, uriForBookMergedPersona, uriForBookPersona, uriForFormVersion, usePrevious } from '../App/Utility';
import { HTTP_STATUS_OK } from '../Constants';
import AnnotatedForm from './AnnotatedForm';
import { clone } from './Comet';

function comparesEqual(obj, prevObj) {
    let equal = true;

    if ((obj && !prevObj) || (!obj && prevObj)) {
        equal = false;
    } else {
        for (let prop of Object.keys(obj)) {
            if (obj[prop] !== prevObj[prop]) {
                equal = false;
                break;
            }
        }
    }

    return equal;
}

function formHasAnnotations(form, prevForm) {
    return form === undefined || !form.annotations ||
        (form.annotations.length <= 0 && prevForm &&
            prevForm.annotations && prevForm.annotations.length > 0);
}

function formOrClone(form, prevForm) {
    if (formHasAnnotations(form, prevForm)) {
        if (prevForm) {
            return prevForm;
        }

        return new AnnotatedForm();
    }

    return clone(form);
}

function useFetchAnnotations(bookObject, formName, formVersion, maxPage,
    mergedPersonaBookObject, mergedPersonaName, pageNumber, personaBookObject,
    personaName
) {
    const [form, setForm] = useState(new AnnotatedForm());
    const [formJson, setFormJson] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const prevArgs = usePrevious({ ...arguments });
    const prevForm = usePrevious(form);

    let uri;

    if (personaName === "" && mergedPersonaName === "") {
        uri = uriForBookFormPage(bookObject.folder, formName, pageNumber, maxPage);
    } else {
        if (personaName === "") {
            uri = uriForBookMergedPersona(mergedPersonaBookObject.folder, mergedPersonaName);
        } else if (personaName !== "") {
            uri = uriForBookPersona(personaBookObject.folder, personaName);
        }
    }

    let hookArguments = arguments;

    useEffect(() => {
        if (!comparesEqual({ ...hookArguments }, prevArgs) || !isLoaded) {
            const fetchData = async () => {
                setIsError(false);
                setIsLoading(true);

                try {
                    Promise.all([uri, uriForFormVersion(formName, formVersion)].map(url =>
                        fetch(url, { cache: "no-cache" }).then(response => {
                            if (response.status !== HTTP_STATUS_OK) {
                                return { status: response.status, message: response.statusText };
                            }

                            return response.json()
                        })
                    )).then(([annotationsJson, formJsonData]) => {
                        let formClone = formOrClone(form, prevForm);

                        if (formJsonData) {
                            formClone.prepare(formName, formJsonData);
                            setFormJson(formJsonData);
                        }

                        if (annotationsJson && !annotationsJson.status) {
                            formClone.loadAnnotations(annotationsJson);
                        }

                        formClone.moveToNextField();

                        setForm(formClone);
                        setIsLoaded(true);
                    });
                } catch (error) {
                    setIsError(true);
                }

                setIsLoading(false);
            };

            fetchData();
        }
    }, [form, formName, formVersion, hookArguments, isLoaded, prevArgs, prevForm, uri]);

    return {
        annotatedForm: form,
        formJson,
        isLoading,
        isError,
        isLoaded,
        setAnnotatedForm: setForm
    };
}

export default useFetchAnnotations;