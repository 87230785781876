import React, { useEffect, useState } from 'react';
import { uriForLogin } from '../App/Utility';
import { useConfig } from '../App/Config';
import './LoginPage.css';

function LoginPage(props) {
    const { config, onLogin, updateConfig } = useConfig(props);
    const [error, setError] = useState();
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    let usernameInput;

    useEffect(() => {
        usernameInput.focus();
        usernameInput.blur();
        usernameInput.focus();
    }, [usernameInput]);

    function handlePasswordChange(event) {
        setError(null);
        setPassword(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        setError(null);
        markStepLogin();

        const loginUri = uriForLogin();
        const payload = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, */*',
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        };

        fetch(loginUri, payload)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.success) {
                        onLogin(result);
                    } else {
                        setError(result.error);
                        markStepInput();
                    }
                },

                (errorResult) => {
                    setError(errorResult);
                    markStepInput();
                }
            );
    }

    function markStepInput() {
        const step = "input";

        updateConfig({ step });
    }

    function markStepLogin() {
        const step = "login";

        updateConfig({ step });
    }

    function handleUsernameChange(event) {
        setError(null);
        setUsername(event.target.value);
    }

    let errorDiv = <div />;
    let loginButton = <input
                        type="submit"
                        id="login"
                        className="btn btn-primary blue"
                        value="Log In"
                        />

    if (error) {
        errorDiv = <div className="row center"><h6>{error}</h6></div>
    }

    if (config.step === "login") {
        loginButton = <h6>Logging in...</h6>
    }

    return (
        <div className="loginPage">
            <nav className="LoginHeader blue lighten-5">
                <span className="brand-logo blue-grey-text text-darken-4"
                        title="Click-Only (or at least Mostly) Extraction Tool">&nbsp; COMET
                </span>
            </nav>
            <div className="container">
                <div className="row">
                    <form
                        className="SignInForm card-panel col s6 offset-s3 blue lighten-5 container"
                        onSubmit={handleSubmit}
                        >
                        <div className="row">
                            <h5 className="col s12 center">Please Sign In</h5>
                        </div>
                        <div className="row">
                            <div className="col s8 offset-s2 input-field">
                                <input
                                    ref={(element) => { usernameInput = element; }}
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    />
                                <label htmlFor="username">Username</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s8 offset-s2 input-field">
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    />
                                <label htmlFor="password">Password</label>
                            </div>
                        </div>
                        <div className="row LoginRow center">
                            {loginButton}
                        </div>
                        {errorDiv}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
