import React, { useCallback, useEffect, useState } from 'react';
import {HIGHLIGHT_COLORS} from '../Constants.js';
import { addClass, each, hasClass, removeClass, uriForPage, usePrevious } from '../App/Utility.js';
import './PageWidget.css';

function PageWidget(props) {
    /*
     * Props:
     *    book              TheElyAncestry
     *    highlightRanges   [(start, end, colorIndex), ...]
     *    maxPage           812
     *    pageDisplayMode   0 = image, 1 = text, 2 = image + red text
     *    pageNumber        575
     *    zoomFactor        .55
     */
    const {
        book, highlightRanges, isAltPressed, maxPage, onSelection,
        pageDisplayMode, pageNumber, zoomFactor
    } = props;
    const [error, setError] = useState();
    const [htmlText, setHtmlText] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    const prevPageNumber = usePrevious(pageNumber);
    const prevBook = usePrevious(book);

    const fetchResource = useCallback(() => {
        if (isLoaded && pageNumber === prevPageNumber) {
            return;
        }

        fetch(uriForPage(book, maxPage, pageNumber, "html"))
            .then(res => res.text())
            .then(
                (result) => {
                    setHtmlText(result);
                    setIsLoaded(true);
                },
                (errorResult) => {
                    setError(errorResult);
                    setIsLoaded(true);
                }
            );
    }, [book, isLoaded, maxPage, pageNumber, prevPageNumber]);

    useEffect(() => {
        fetchResource();
    }, [fetchResource]);

    useEffect(() => {
        let selection = {
            text: "",
            pos1: { x: 0, y: 0 },
            pos2: { x: 0, y: 0 },
            startOffset: 0,
            endOffset: 0,
        };

        function expandSelectionNodes(details) {
            if (isAltPressed) {
                return expandSelectionNodesWithPunctuation(details);
            }

            // Previous and next will only be letters.
            // "-\n" is a special case that is also allowed.
            let prev = details.firstNode.previousElementSibling;

            while ((prev && hasClass(prev, "letter") && prev.textContent.match(/([0-9]|\p{L})+/u))
                || (prev && hasClass(prev, "newline") && prev.previousElementSibling.textContent === "-")) {
                if (hasClass(prev, "newline")
                    && hasClass(prev.previousElementSibling.previousElementSibling, "letter")) {
                    details.firstNode = prev;
                    prev = details.firstNode.previousElementSibling;
                }

                details.firstNode = prev;
                prev = details.firstNode.previousElementSibling;
            }

            let next = details.lastNode.nextElementSibling;

            while ((hasClass(next, "letter") && next.textContent.match(/([0-9]|\p{L})+/u))
                || (next.textContent === "-" && hasClass(next.nextElementSibling, "newline"))) {
                if (next.textContent === "-" && hasClass(next.nextElementSibling.nextElementSibling, "letter")) {
                    details.lastNode = next;
                    next = details.lastNode.nextElementSibling;
                }

                details.lastNode = next;
                next = details.lastNode.nextElementSibling;
            }
        }

        function expandSelectionNodesWithPunctuation(details) {
            // Previous and next will be any character except spaces.
            // Prevents annotation when click is on a space.
            if (details.firstNode !== details.lastNode || hasClass(details.firstNode, "letter")) {
                let prev = details.firstNode.previousElementSibling;

                while ((prev && hasClass(prev, "letter") && prev.textContent.match(/[^\s\n\t]+/i))
                    || (prev && hasClass(prev, "newline") && prev.previousElementSibling.textContent === "-")) {
                    if (hasClass(prev, "newline") && hasClass(prev.previousElementSibling.previousElementSibling, "letter")) {
                        details.firstNode = prev;
                        prev = details.firstNode.previousElementSibling;
                    }

                    details.firstNode = prev;
                    prev = details.firstNode.previousElementSibling;
                }

                let next = details.lastNode.nextElementSibling;

                while ((next && hasClass(next, "letter") && next.textContent.match(/[^\s\n\t]+/))
                    || (next && hasClass(next, "newline") && details.lastNode.textContent === "-")) {
                    details.lastNode = next;
                    next = details.lastNode.nextElementSibling;
                }
            }
        }

        function extractTextSelection(details) {
            if (details.firstNode) {
                details.pos1.x = details.firstNode.offsetLeft;
                details.pos1.y = details.firstNode.offsetTop;
                details.pos2.x = details.pos1.x + details.firstNode.offsetWidth;
                details.pos2.y = details.pos1.y + details.firstNode.offsetHeight;

                details.textValue = details.firstNode.textContent;
                details.originalText = details.textValue;
            }

            // Set the start offset before we start changing details.firstNode
            details.startOffset = Number(details.firstNode.getAttribute("offset"));

            if (details.firstNode !== details.lastNode) {
                details.firstNode = details.firstNode.nextElementSibling;

                while (details.firstNode !== details.lastNode) {
                    if (getComputedStyle(details.firstNode)["width"] !== "0px") {
                        if (details.isSelection && details.firstNode.textContent === "-"
                            && hasClass(details.firstNode.nextElementSibling, "newline")) {
                            // Removes "-\n" from selection if the text was in a selection
                            // rather than a word that was clicked
                            details.originalText += "- ";
                            details.firstNode = details.firstNode.nextElementSibling;

                            if (details.firstNode === details.lastNode) {
                                break;
                            }
                        } else if (isAltPressed && details.firstNode.textContent === "-") {
                            // Remove \n from annotation if alt is pressed; preserve "-" character
                            if (details.firstNode.nextElementSibling
                                && hasClass(details.firstNode.nextElementSibling, "newline")) {
                                details.originalText += "- ";
                                details.textValue += "- ";
                                details.firstNode = details.firstNode.nextElementSibling;

                                // Prevent going past the last node.
                                if (details.firstNode === details.lastNode) {
                                    break;
                                }
                            } else {
                                details.textValue += details.firstNode.textContent;
                                details.originalText += details.firstNode.textContent;
                            }
                        } else if (!isAltPressed && details.firstNode.textContent === "-") {
                            // Removes the newline character from annotation if alt is not.
                            if (details.firstNode.nextElementSibling
                                && hasClass(details.firstNode.nextElementSibling, "newline")) {
                                details.originalText += "- ";
                                // Note: skip appending to the textValue -- only add to user text
                                details.firstNode = details.firstNode.nextElementSibling;

                                // Prevent going past the last node.
                                if (details.firstNode === details.lastNode) {
                                    break;
                                }
                            } else {
                                details.textValue += details.firstNode.textContent;
                                details.originalText += details.firstNode.textContent;
                            }
                        } else if (hasClass(details.firstNode, "newline") || hasClass(details.firstNode, "space")) {
                            details.textValue += " ";
                            details.originalText += " ";
                        } else {
                            details.textValue += details.firstNode.textContent;
                            details.originalText += details.firstNode.textContent;
                        }
                    }

                    details.firstNode = details.firstNode.nextElementSibling;
                }

                details.pos1.x = Math.min(details.firstNode.offsetLeft, details.pos1.x);
                details.pos1.y = Math.min(details.firstNode.offsetTop, details.pos1.y);
                details.pos2.x = Math.max(details.firstNode.offsetLeft + details.firstNode.offsetWidth, details.pos2.x);
                details.pos2.y = Math.max(details.firstNode.offsetTop + details.firstNode.offsetHeight, details.pos2.y);

                details.textValue += details.firstNode.textContent;
                details.originalText += details.firstNode.textContent;
            }

            details.endOffset = Number(details.lastNode.getAttribute("offset"));
        }

        function findSelectionNodes(details, htmlSelection) {
            if (!htmlSelection.isCollapsed && htmlSelection.rangeCount > 0) {
                // We have a selection range
                let anchorNode = parentForTextNode(htmlSelection.anchorNode);

                if (isTextNode(anchorNode)) {
                    // First is start node of first range
                    details.firstNode = parentForTextNode(htmlSelection.getRangeAt(0).startContainer);
                    details.lastNode = parentForTextNode(htmlSelection.getRangeAt(htmlSelection.rangeCount - 1).endContainer);

                    if (!isTextNode(details.firstNode) || !isTextNode(details.lastNode)) {
                        // But we only want to select PDF text nodes
                        details.firstNode = null;
                        details.lastNode = null;
                    }
                }
            }

            if (details.firstNode !== details.lastNode) {
                // The user has made a selection rather than a click with no selection
                details.isSelection = true;
            }

            if (details.firstNode === null) {
                // We don't have first/last nodes identified, so try within first range
                details.firstNode = parentForTextNode(htmlSelection.getRangeAt(0).startContainer);
                details.lastNode = parentForTextNode(htmlSelection.getRangeAt(0).startContainer);

                details.isSelection = false;

                if (details.firstNode !== details.lastNode) {
                    // Selection rather than a click with no selection
                    details.isSelection = true;
                }

                expandSelectionNodes(details);
            }
        }

        function getTextSelection() {
            let selectionDetails = getTextSelectionDetails();

            if (selectionDetails) {
                selection = {
                    text: selectionDetails.textValue,
                    originalText: selectionDetails.originalText,
                    pos1: {
                        x: selectionDetails.pos1.x,
                        y: selectionDetails.pos1.y,
                    },
                    pos2: {
                        x: selectionDetails.pos2.x,
                        y: selectionDetails.pos2.y,
                    },
                    startOffset: Number(selectionDetails.startOffset),
                    endOffset: Number(selectionDetails.endOffset),
                };
            }

            let htmlSelection = window.getSelection();

            if (htmlSelection !== undefined) {
                htmlSelection.removeAllRanges();
            }
        }

        function getTextSelectionDetails() {
            let htmlSelection = window.getSelection();
            let details = {
                firstNode: null,
                lastNode: null,
                isSelection: false,
                pos1: { x: 0, y: 0 },
                pos2: { x: 0, y: 0 },
                originalText: "",
                textValue: ""
            };

            if (htmlSelection === undefined || htmlSelection.rangeCount <= 0) {
                return null;
            }

            if (htmlSelection !== undefined) {
                findSelectionNodes(details, htmlSelection);
            }

            restrictSelectionToLetters(details);
            extractTextSelection(details);

            selection = {
                text: details.textValue,
                originalText: details.originalText,
                pos1: details.pos1,
                pos2: details.pos2,
                startOffset: Number(details.startOffset),
                endOffset: Number(details.endOffset),
            };

            return details;
        }

        function highlightRange(start, end, colorIndex) {
            if (start >= end) {
                return;
            }

            highlightBorder(start, end, "hoverlhl", "hovermhl", "hoverrhl");
            highlightBackground(start, end, HIGHLIGHT_COLORS[colorIndex % HIGHLIGHT_COLORS.length]);
        }

        function highlightFields() {
            removeAllHighlights();

            if (Array.isArray(highlightRanges)) {
                highlightRanges.forEach(function (range) {
                    highlightRange(Number(range[0]), Number(range[1]), Number(range[2]));
                });
            }
        }

        function notifySelection() {
            getTextSelection();
            onSelection(selection);
        }

        function setTextHtmlClick() {
            each("div.letter, div.space, div.newline", function (element, i) {
                element.onclick = function (event) {
                    event.preventDefault();
                    notifySelection();
                };

                element.onmouseover = function (event) {
                    addClass(element, "hover");
                }

                element.onmouseout = function (event) {
                    removeClass(element, "hover");

                    if (hasClass(element, "lhl")) {
                        removeClass(element, "lhl");
                        addClass(element, "lhl");
                    }

                    if (hasClass(element, "rhl")) {
                        removeClass(element, "rhl");
                        addClass(element, "rhl");
                    }

                    if (hasClass(element, "mhl")) {
                        removeClass(element, "mhl");
                        addClass(element, "mhl");
                    }
                }

                element.style.cursor = "text";
            });
        }

        function setZoomLevel() {
            each("#page", function (element, i) {
                element.style.transform = "scale(" + zoomFactor + "," + zoomFactor + ")";
            });
        }

        if (error || prevPageNumber !== pageNumber || prevBook !== book) {
            fetchResource();
        } else {
            highlightFields();
            setTextHtmlClick();
            setZoomLevel();
        }
    }, [book, error, fetchResource, highlightRanges, isAltPressed, onSelection, pageNumber,
        prevBook, prevPageNumber, zoomFactor]);

    function highlightBackground(start, end, color) {
        let i = start;

        while (i < end) {
            each("[offset='" + i + "']", function (element, index) {
                element.style.backgroundColor = "rgba(" + color.r + "," +
                    color.g + "," + color.b + "," + color.a + ")";
            });

            i += 1;
        }
    }

    function highlightBorder(start, end, left, middle, right) {
        let i = start + 1;

        while (i < end - 1) {
            each("#page .letter[offset='" + i + "'], #page .space[offset='" + i +
                "'], #page .newline[offset='" + i + "']", function (element, index) {
                    addClass(element, middle);
                });

            i += 1;
        }

        each("#page .letter[offset='" + start + "'], #page .space[offset='" + start +
            "'], #page .newline[offset='" + start + "']", function (element, index) {
                addClass(element, left);
            });

        each("#page .letter[offset='" + (end - 1) + "'], #page .space[offset='" + (end - 1) +
            "'], #page .newline[offset='" + (end - 1) + "']", function (element, index) {
                addClass(element, right);
            });
    }

    function isTextNode(node) {
        return node.getAttribute("class").match(/letter|space|newLine/);
    }

    function parentForTextNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            node = node.parentNode;
        }

        return node;
    }

    function removeAllHighlights() {
        each(".hoverlhl, .hovermhl, .hoverrhl", function (element, index) {
            element.style.backgroundColor = "rgba(0,0,0,0)";
        });

        each(".hoverlhl", function (element, index) {
            removeClass(element, "hoverlhl");
        });

        each(".hovermhl", function (element, index) {
            removeClass(element, "hovermhl");
        });

        each(".hoverrhl", function (element, index) {
            removeClass(element, "hoverrhl");
        });
    }

    function resourceUriPng() {
        return uriForPage(book, maxPage, pageNumber, "png");
    }

    function restrictSelectionToLetters(details) {
        while (details.firstNode && !hasClass(details.firstNode, "letter")) {
            details.firstNode = details.firstNode.nextElementSibling;
        }

        while (details.lastNode && !hasClass(details.lastNode, "letter")) {
            details.lastNode = details.lastNode.previousElementSibling;
        }
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        const backgroundUrl = /background-image:url[(][^)]*[)];\s*/i;
        const htmlObject = {__html: htmlText.replace(backgroundUrl, "")};
        const classNameSpec = "PageHolder image mode" + pageDisplayMode;
        const matches = htmlText.match(/width:([0-9.]+)px; height:([0-9.]+)px;/);

        const pageStyle = {
            background: "#ffffff",
            backgroundImage: Number(pageDisplayMode) !== 1 ? "url(" + resourceUriPng() + ")" : "",
            width: Math.floor(Number(matches[1])) + "px",
            height: Math.floor(Number(matches[2])) + "px",
        };

        return (
            <div className="PageWidget">
                <div id="page" className={classNameSpec} style={pageStyle}>
                    <div dangerouslySetInnerHTML={htmlObject} />
                </div>
            </div>
        );
    }
}

export default PageWidget;
