import React, { useEffect } from 'react';
import { FIELD_TYPE_SINGLE, HINT_TYPE_BREAK } from '../Constants.js';
import { camelCaseToSpaces, getIndent, uniqueId } from '../App/Utility.js';
import './FormLabels.css';
import M from 'materialize-css/dist/js/materialize.min.js';

function FormLabels(props) {
    const {
        currentFieldName, formJson, onDeselectAll, onDone, onExecuteSelected,
        onSave, onSelectAll, onToggleHelp, personaName
    } = props;

    useEffect(() => {
        function init() {
            const elems = document.querySelectorAll('.dropdown-trigger');

            M.Dropdown.init(elems, {
                alignment: "right",
                constrainWidth: false,
                coverTrigger: true,
            });
        }

        if ( document.attachEvent ?
             document.readyState === "complete" :
             document.readyState !== "loading" ) {
            init();
        } else {
            document.addEventListener('DOMContentLoaded', init);
        }
    }, []);

    function renderLabelForField(field) {
        if (field.type === FIELD_TYPE_SINGLE) {
            let className = "FormLabel";

            if (field.label === currentFieldName) {
                className += " current";
            }

            return <div className={className} key={uniqueId()}>{camelCaseToSpaces(field.label)}</div>;
        } else {
            let nestedLabels = [];
            let className = "LabelMultipleGroup offset" + offset;

            offset += 1;

            field.fields.forEach((subfield) => {
                nestedLabels.push(renderLabelForField(subfield));
            });

            return <div className={className} key={uniqueId()}>{nestedLabels}</div>;
        }
    }

    function dropdownMenu(mode) {
        if (mode === "GreenQQ") {
            return (
                <div>
                    <button title="Show Form Menu"
                        className="gray-text text-darken-1 btn-flat ShowFormMenu dropdown-trigger waves-effect waves-light"
                        data-target="dropdown2"><i
                        className="material-icons">menu</i>
                    </button>
                    <ul id="dropdown2" className="dropdown-content" tabIndex="0">
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu0"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onSave}
                            ><i className="material-icons left">save</i> Save</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu1"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onSelectAll}
                            ><i className="material-icons left">select_all</i> Select All</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu2"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown modal-trigger"
                            onClick={onDeselectAll}
                            ><i className="material-icons left">ballot</i> Deselect All</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu3"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onExecuteSelected}
                            ><i className="material-icons left">send</i> Execute Selected</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu4"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown modal-trigger"
                            data-target="confirmModal"
                            ><i className="material-icons left">close</i> Close</button></li>
                    </ul>
                </div>
            );
        } else {
            return (
                <div>
                    <button title="Show Form Menu"
                        className="gray-text text-darken-1 btn-flat ShowFormMenu dropdown-trigger waves-effect waves-light"
                        data-target="dropdown2"><i
                        className="material-icons">menu</i>
                    </button>
                    <ul id="dropdown2" className="dropdown-content" tabIndex="0">
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu0"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onSave}
                            ><i className="material-icons left">save</i> Save</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu1"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onToggleHelp}
                            ><i className="material-icons left">help</i> Help</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu2"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown modal-trigger"
                            data-target="jsonModal"
                            ><i className="material-icons left">subject</i> Show JSON</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu3"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={onDone}
                            ><i className="material-icons left">send</i> Save &amp; Exit</button></li>
                        <li className="dropdownItem" tabIndex="0"><button id="formmenu4"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown modal-trigger"
                            data-target="confirmModal"
                            ><i className="material-icons left">close</i> Exit</button></li>
                    </ul>
                </div>
            );
        }
    }

    let labels = [];
    let menu = dropdownMenu(personaName);
    let offset = 0;

    if (formJson && formJson.fields) {
        formJson.fields.forEach((field) => {
            if (field.hint) {
                if (field.hint.includes(HINT_TYPE_BREAK)) {
                    labels.push(<br className="recordWrap" key={uniqueId()} />);
                }

                let indent = getIndent(field.hint);

                while (indent > 0) {
                    labels.push(<div className="recordSpacer" key={uniqueId()} />);
                    indent -= 1;
                }
            }

            labels.push(renderLabelForField(field));
        });
    }

    return (
        <div className="FormLabels z-depth-1">
            <div className="FormLabelsContainer">
                <div className="FormLabelsHolder">{labels}</div>
                {menu}
            </div>
        </div>
    );
}

export default FormLabels;
