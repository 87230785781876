import { useEffect, useRef } from 'react';

const FUNCTION_REGISTER = "observe";
const FUNCTION_UNREGISTER = "unobserve";

function useResizeObserver({ callback, element }) {
    const observer = useRef(null);

    useEffect(() => {
        const register = (prop) => {
            if (observer && observer.current && element) {
                if (Array.isArray(element)) {
                    element.forEach(entry => {
                        if (entry && entry.current) {
                            observer.current[prop](entry.current);
                        }
                    });
                } else {
                    if (element.current) {
                        observer.current[prop](element.current);
                    }
                }
            }
        }

        register(FUNCTION_UNREGISTER);
        observer.current = new ResizeObserver(callback);
        register(FUNCTION_REGISTER);

        return () => {
            register(FUNCTION_UNREGISTER);
        };
    }, [callback, element]);
}

export default useResizeObserver;