import React, { useEffect, useState } from 'react';
import { camelCaseToSpaces } from '../App/Utility.js';
import './HeaderWidget.css';
import M from 'materialize-css/dist/js/materialize.min.js';

function HeaderWidget(props) {
    const {
        book, firstPage, formName, displayPageNumber, lastPage, maxPage, mergedPersonaName,
        onPageNext, onPagePrevious, onPageSelect, onPageSettings, onZoomIn, onZoomOut,
        pageDisplayMode, pageNumber, personaName, skipBottom, skipTop
    } = props;
    const [pageNumberInputValue, setPageNumberInputValue] = useState(displayPageNumber);

    useEffect(() => {
        function init() {
            const elems = document.querySelectorAll('.dropdown-trigger');

            M.Dropdown.init(elems, { coverTrigger: false });
        }

        if (document.attachEvent ?
            document.readyState === "complete" :
            document.readyState !== "loading") {
            init();
        } else {
            document.addEventListener('DOMContentLoaded', init);
        }
    }, []);

    useEffect(() => {
        if (pageNumberInputValue !== displayPageNumber &&
            pageNumberInputValue !== "") {

            setPageNumberInputValue(displayPageNumber);
        }
    }, [displayPageNumber, pageNumberInputValue]);

    function handleInputChange(e) {
        if (e.target.value === "") {
            setPageNumberInputValue("");
        } else {
            let page = Math.floor(Number(e.target.value));

            if (page < 1 || isNaN(page)) {
                page = 1;
            }

            if (page > maxPage) {
                page = maxPage;
            }

            onPageSelect(page);
            setPageNumberInputValue(page);
        }
    }

    function setPageDisplayMode(e) {
        e.preventDefault();
        onPageSettings(e.target.getAttribute("id").slice(-1));
    }

    const mode = Number(pageDisplayMode);
    let check = [];
    let formDescription = camelCaseToSpaces(book);
    let toolTitle = "COMET";
    let persona = `${mergedPersonaName}${personaName}`;

    if (persona === "") {
        formDescription = `${formName} Form, ${formDescription}, ${pageNumber}`;
    } else if (persona === "GreenQQ") {
        toolTitle = persona;
        formDescription = `${formDescription}, Pages ${firstPage}–${lastPage}, Skip ${skipTop} ${skipBottom}`;
    } else {
        formDescription = `${formDescription}, Persona ${persona}`;
    }

    for (let i = 0; i <= 2; i++) {
        check[i] = mode === i ? "✓" : "";
    }

    return(
        <nav className="HeaderWidget blue lighten-5">
            <span className="brand-logo blue-grey-text text-darken-4"
                    title="Click-Only (or at least Mostly) Extraction Tool">&nbsp; {toolTitle} &nbsp;&nbsp;
                <span className="text-smaller">{formDescription}</span>
            </span>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
                <li><button
                        title="Previous Page"
                        className="blue-grey-text text-darken-4 btn-flat btn-header"
                        onClick={onPagePrevious}><i
                        className="material-icons">skip_previous</i></button></li>
                <li><input id="resourceNum" className="blue-grey-text text-darken-4"
                            type="text" value={pageNumberInputValue}
                            onChange={handleInputChange} /></li>
                <li><span id="resourceTotal" className="blue-grey-text text-darken-4">
                        &nbsp;of {maxPage}</span></li>
                <li><button
                        title="Next Page"
                        className="blue-grey-text text-darken-4 btn-flat btn-header"
                        onClick={onPageNext}><i
                        className="material-icons">skip_next</i></button></li>
                <li><a className="dropdown-trigger blue-grey-text text-darken-4"
                        href="#!" data-target="dropdown1">Page Settings</a>
                    <ul id="dropdown1" className="dropdown-content">
                        <li className="dropdownItem"><button id="displaymode0"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={setPageDisplayMode}>{check[0]} Show Image</button></li>
                        <li className="dropdownItem"><button id="displaymode1"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={setPageDisplayMode}>{check[1]} Show Text</button></li>
                        <li className="dropdownItem"><button id="displaymode2"
                            className="blue-grey-text text-darken-4 btn-flat btn-header btn-dropdown"
                            onClick={setPageDisplayMode}>{check[2]} Show <span id="mode2"
                                className="inline-text red-text">Red</span> Text
                            </button></li>
                    </ul>
                </li>
                <li><button
                        title="Zoom In"
                        className="blue-grey-text text-darken-4 btn-flat btn-header"
                        onClick={onZoomIn}><i className="material-icons">zoom_in</i></button></li>
                <li><button
                        title="Zoom Out"
                        className="blue-grey-text text-darken-4 btn-flat btn-header"
                        onClick={onZoomOut}><i className="material-icons">zoom_out</i></button></li>
            </ul>
        </nav>
    );
}

export default HeaderWidget;
