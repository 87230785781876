import React, { useEffect } from 'react';
import { useConfig } from '../App/Config';
import { BUTTON_CLASS } from '../Constants';

function EditPageForm(props) {
    const { generateBooksSelect, receiveFocus } = props;
    const { config, onEdit, updateConfig } = useConfig();

    let bookSelect;
    let formSelect;
    let pageNumberInput;
    let books = generateBooksSelect(handleBookSelection);
    let buttonClass = BUTTON_CLASS;

    if (config.book === "" || config.formName === "") {
        buttonClass += " disabled";
    }

    function handleBookSelection(event) {
        event.preventDefault();

        const selection = bookSelect.options[bookSelect.selectedIndex];
        let bookObject = config.booksList[selection.value];

        if (selection.value === bookObject.shortTitle) {
            let pageNumber = config.pageNumber;
            const maxPage = bookObject.maxPage;

            if (pageNumber > bookObject.maxPage) {
                pageNumber = bookObject.maxPage;
            }

            updateConfig({ book: bookObject.shortTitle, bookObject, maxPage, pageNumber });
        }
    }

    function handleChange(event) {
        if (event.target.value === "") {
            updateConfig({ pageNumber: "" });
            return;
        }

        let pageNumber = Number(event.target.value);

        if (pageNumber < 1 || isNaN(pageNumber)) {
            pageNumber = 1;
        } else if (pageNumber > config.maxPage) {
            pageNumber = config.maxPage;
        }

        updateConfig({ pageNumber });
    }

    function handleFormSelection(event) {
        event.preventDefault();

        const selection = formSelect.options[formSelect.selectedIndex];
        const formName = selection.value;

        updateConfig({ formName });
    }

    useEffect(() => {
        pageNumberInput.focus();
        pageNumberInput.blur();
    }, [pageNumberInput]);

    let bookValue = config.book;

    if (!bookValue) {
        if (config.bookObject && config.bookObject.shortTitle) {
            bookValue = config.bookObject.shortTitle;
        }
    }

    return (
        <form className="EditPageForm" onSubmit={(e) => { e.preventDefault(); onEdit(); }}>
            <div className="row">
                <h5 className="col s12">Choose a book, form, and page to annotate:</h5>
            </div>
            <div className="row">
                <div className="input-field col s5">
                    <select
                        className="browser-default"
                        id="BookSelect"
                        ref={(element) => { bookSelect = element; }}
                        onChange={handleBookSelection}
                        value={bookValue}
                    >
                        {books}
                    </select>
                </div>
                <div className="input-field col s3">
                    <select
                        className="browser-default"
                        id="FormSelect"
                        ref={(element) => { formSelect = element; }}
                        onChange={handleFormSelection}
                        value={config.formName}
                    >
                        <option value="" disabled key="chooseform">Choose a form</option>
                        <option value="ChildOf" key="childOf">Child Of</option>
                        <option value="Marriage" key="couple">Marriage</option>
                        <option value="Family" key="family">Family</option>
                        <option value="Individual" key="individual">Individual</option>
                        <option value="Person" key="person">Person</option>
                        <option value="TreeReady" key="treeready">Tree Ready</option>
                        <option value="Household" key="household">Household</option>
                    </select>
                </div>
                <div className="input-field col s2">
                    <input
                        type="text"
                        id="pageNumber"
                        ref={(input) => { pageNumberInput = input; }}
                        value={config.pageNumber}
                        onChange={handleChange}
                        onFocus={receiveFocus}
                    />
                    <label htmlFor="pageNumber">Page</label>
                    <span className="helper-text">Max page {config.maxPage}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s2 offset-s8">
                    <button
                        className={buttonClass}
                        type="submit"
                        name="action"
                        onClick={(e) => { e.preventDefault(); onEdit(); }}
                    >Open <i className="material-icons right">send</i>
                    </button>
                </div>
            </div>
        </form>
    );
}

export default EditPageForm;