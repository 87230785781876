import React, { createContext, useContext, useMemo, useState } from 'react';
import { cloneObject, formattedPageNumber, removeKeys, uriForSave } from './Utility';
import M from 'materialize-css/dist/js/materialize.min.js';

const ConfigContext = createContext();

function useConfig() {
    const context = useContext(ConfigContext);

    if (!context) {
        throw new Error(`useConfig must be used within a ConfigProvider`);
    }

    const [config, setConfig] = context;

    async function saveJson(formJson, mode) {
        formJson = cloneObject(formJson);

        formJson.records.forEach((record) => {
            removeKeys(record);
        });

        let bookObject = config.booksList[config.book];

        if (bookObject === undefined) {
            throw new Error(`Unable to find book "${config.book}" in meta.json`);
        }

        const saveUri = uriForSave();
        const payload = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, */*',
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify(
                {
                    folder: bookObject.folder,
                    formJson,
                    formName: config.formName,
                    mergedPersonaName: config.mergedPersonaName,
                    mode,
                    pageNumber: formattedPageNumber(config.pageNumber, config.maxPage),
                    personaName: config.personaName,
                    session: config.session,
                }
            ),
        };

        let success = false;

        await fetch(saveUri, payload)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.success) {
                        success = true;
                    } else {
                        M.toast({ html: "<span>Error saving: " + result.error + "</span>" });
                    }
                },

                (error) => {
                    M.toast({ html: "<span>Error saving: " + error + "</span>" });
                }
            );

        if (success) {
            M.toast({ html: "<span>Saved to server</span>" });
        }

        return success;
    }

    function onCancel() {
        const newConfig = Object.assign({}, config);

        newConfig.step = "admin";
        setConfig(newConfig);
    }

    function onDone(formJson) {
        const success = saveJson(formJson, "final");

        if (success) {
            onCancel();
        }
    }

    const onEdit = () => {
        let pageNumber = config.pageNumber;
        let formVersion = 2;

        if (pageNumber === "") {
            pageNumber = 1;
        }

        if (config.formName === "Individual") {
            formVersion = 3;
        }

        const newConfig = Object.assign(
            {}, config,
            {
                formVersion,
                pageNumber,
                mergedPersonaName: "",
                personaName: "",
                step: null,
            }
        );

        setConfig(newConfig);
    };

    function onEditMergedPersona() {
        const newConfig = Object.assign(
            {}, config,
            {
                // NEEDSWORK: do we need to assign mergedPersonaBook to book?
                book: config.mergedPersonaBook,
                formName: "TreeReady",
                personaName: "",
                step: null,
            }
        );

        updateBookDependencies(newConfig);
        setConfig(newConfig);
    }

    function onEditPersona() {
        const newConfig = Object.assign(
            {}, config,
            {
                // NEEDSWORK: do we need to assign personaBook to book?
                book: config.personaBook,
                formName: "TreeReady",
                mergedPersonaName: "",
                step: null,
            }
        );

        updateBookDependencies(newConfig);
        setConfig(newConfig);
    }

    function onGreenQQ() {
        const newConfig = Object.assign(
            {}, config,
            {
                formName: "Individual",
                pageNumber: config.firstPage,
                step: "GreenQQ",
            }
        );

        updateBookDependencies(newConfig);
        setConfig(newConfig);
    }

    function onLogin(response) {
        const newConfig = Object.assign({}, config);
        let booksList = [];

        response.meta.forEach(book => {
            booksList[book.shortTitle] = book;
        });

        newConfig.meta = response.meta;
        newConfig.session = response.session;
        newConfig.booksList = booksList;
        newConfig.step = "admin";
        newConfig.userid = response.userid;
        newConfig.username = response.username;
        setConfig(newConfig);
    }

    function onPageNext() {
        const newConfig = Object.assign({}, config);

        newConfig.pageNumber = newConfig.pageNumber < newConfig.maxPage
            ? Number(newConfig.pageNumber) + 1
            : newConfig.pageNumber;

        setConfig(newConfig);
    }

    function onPagePrevious() {
        const newConfig = Object.assign({}, config);

        newConfig.pageNumber = newConfig.pageNumber > 1
            ? Number(newConfig.pageNumber) - 1
            : newConfig.pageNumber;

        setConfig(newConfig);
    }

    function onPageSelect(page) {
        let newPage = Math.floor(Number(page));

        if (newPage < 1 || isNaN(newPage)) {
            newPage = 1;
        }

        if (newPage > this.state.maxPage) {
            newPage = this.state.maxPage;
        }

        const newConfig = Object.assign({}, config);

        newConfig.pageNumber = newPage;

        setConfig(newConfig);
    }

    function onSave(formJson) {
        saveJson(formJson, "working");
    }

    function onSaveGreenQQ(formJson) {
        // NEEDSWORK: decide what to do for this operation
    }

    function updateBookDependencies(someConfig) {
        someConfig.bookObject = null;
        someConfig.greenQQBookObject = null;
        someConfig.mergedPersonaBookObject = null;
        someConfig.personaBookObject = null;

        if (someConfig.meta) {
            someConfig.bookObject = config.booksList[someConfig.book];
            someConfig.greenQQBookObject = config.booksList[someConfig.greenQQBook];
            someConfig.mergedPersonaBookObject = config.booksList[someConfig.mergedPersonaBook];
            someConfig.personaBookObject = config.booksList[someConfig.personaBook];

            if ((someConfig.bookObject === null && someConfig.book && someConfig.book !== "") ||
                (someConfig.greenQQBookObject === null && someConfig.greenQQBook && someConfig.greenQQBook !== "") ||
                (someConfig.mergedPersonaBookObject === null && someConfig.mergedPersonaBook && someConfig.mergedPersonaBook !== "") ||
                (someConfig.personaBookObject === null && someConfig.personaBook && someConfig.personaBook !== "")) {

                throw new Error(`Unable to find book "${someConfig.book}" or "${someConfig.greenQQBook}" or "${someConfig.mergedPersonaBook}" or "${someConfig.personaBook}" in meta.json`);
            }
        }
    }

    function updateConfig(updatedConfig) {
        setConfig(Object.assign({}, config, updatedConfig));
    }

    return {
        config,
        setConfig,
        onCancel,
        onDone,
        onEdit,
        onEditMergedPersona,
        onEditPersona,
        onGreenQQ,
        onLogin,
        onPageNext,
        onPagePrevious,
        onPageSelect,
        onSave,
        onSaveGreenQQ,
        updateConfig,
    };
}

function ConfigProvider(props) {
    const [config, setConfig] = useState({
        book: "",
        bookObject: null,
        formName: "",
        formVersion: 2,
        pageNumber: 0,
        maxPage: 0,
        greenQQBook: "TheElyAncestry",
        greenQQBookObject: null,
        firstPage: 573,
        lastPage: 575,
        greenQQMaxPage: 830,
        skipBottom: 0,
        skipTop: 2,
        mergedPersonaBook: "",
        mergedPersonaBookObject: null,
        mergedPersonaName: "",
        personaBook: "",
        personaBookObject: null,
        personaName: "",
        meta: null,
        session: null,
        step: null,
    });
    const value = useMemo(() => [config, setConfig], [config]);

    return <ConfigContext.Provider value={value} {...props} />;
}

export { ConfigProvider, useConfig };