import { DEBUG_CACHE, FIELD_TYPE_MULTIPLE, FIELD_TYPE_SINGLE,
         INSERT_AFTER } from '../Constants';
import { cloneObject, each, uniqueId, } from '../App/Utility';

class AnnotatedForm {
    constructor(copy) {
        this.annotations = null;
        this.cache = {};
        this.currentFieldId = null;
        this.currentFieldName = null;
        this.recordTemplate = null;

        if (copy) {
            this.annotations = copy.annotations;
            this.cache = copy.cache;
            this.currentFieldId = copy.currentFieldId;
            this.currentFieldName = copy.currentFieldName;
            this.recordTemplate = copy.recordTemplate;
        }

        this.addNestedRecords = this.addNestedRecords.bind(this);
        this.addNewRecord = this.addNewRecord.bind(this);
        this.addUniqueKeys = this.addUniqueKeys.bind(this);
        this.annotateField = this.annotateField.bind(this);
        this.changeFieldValue = this.changeFieldValue.bind(this);
        this.cloneNestedGroup = this.cloneNestedGroup.bind(this);
        this.compareKeys = this.compareKeys.bind(this);
        this.compareRecords = this.compareRecords.bind(this);
        this.currentField = this.currentField.bind(this);
        this.currentFieldIsInRecord = this.currentFieldIsInRecord.bind(this);
        this.currentMultipleField = this.currentMultipleField.bind(this);
        this.deleteAnnotation = this.deleteAnnotation.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.dumpCache = this.dumpCache.bind(this);
        this.generateFieldListForRecords = this.generateFieldListForRecords.bind(this);
        this.insertNewRecord = this.insertNewRecord.bind(this);
        this.loadAnnotations = this.loadAnnotations.bind(this);
        this.prepare = this.prepare.bind(this);
        this.prepareField = this.prepareField.bind(this);
        this.prepareNestedGroup = this.prepareNestedGroup.bind(this);
        this.prepareRecord = this.prepareRecord.bind(this);
        this.rebuildCache = this.rebuildCache.bind(this);
        this.removeLastNestedRecord = this.removeLastNestedRecord.bind(this);
        this.setCurrentField = this.setCurrentField.bind(this);
        this.sortRecord = this.sortRecord.bind(this);
        this.targetRecordGroup = this.targetRecordGroup.bind(this);
        this.uncacheRecord = this.uncacheRecord.bind(this);
    }

    dumpCache(message) {
        if (DEBUG_CACHE) {
            let multipleCount = 0;
            let recordCount = 0;
            let singleCount = 0;

            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", message);

            Object.keys(this.cache).forEach((key) => {
                if (this.cache[key].type === FIELD_TYPE_SINGLE) {
                    singleCount += 1;
                    console.log(key, "single", this.cache[key].label, this.cache[key].userText);
                } else if (this.cache[key].type === FIELD_TYPE_MULTIPLE) {
                    multipleCount += 1;
                    console.log(key, "multiple", this.cache[key].label, this.cache[key].records.length);
                } else {
                    recordCount += 1;
                    console.log(key, "record", this.cache[key].length, this.cache[key]);
                }
            });

            console.log(message, singleCount, multipleCount, recordCount, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
        }
    }

    addNestedRecords(count) {
        if (!this.currentFieldId) {
            return;
        }

        const multipleField = this.currentMultipleField();
        let addedCount = 0;

        if (multipleField && multipleField.records && multipleField.records.length > 0) {
            while (count > 0) {
                count -= 1;
                addedCount += 1;
                this.cloneNestedGroup(multipleField);
            }
        }

        this.dumpCache("addNestedRecords");

        return addedCount;
    }

    addNewRecord(records, index) {
        let record = cloneObject(this.recordTemplate);
        const firstId = this.addUniqueKeys(record);

        if (typeof index === "undefined" || index < 0 || index >= records.length) {
            records.push(record);
        } else {
            records.splice(index, 0, record);
        }

        return firstId;
    }

    addUniqueKeys(record, sortKeys) {
        let firstId = null;

        if (typeof sortKeys === "undefined") {
            record.sortKeys = [];
            sortKeys = record.sortKeys;
        }

        record.id = uniqueId();
        // console.log("addUniqueKeys record.id", record.id);
        this.cache[record.id] = record;

        record.forEach((field, index) => {
            field.id = uniqueId();
            // console.log("addUniqueKeys field.id", field.id);
            this.cache[field.id] = field;

            if (firstId === null) {
                firstId = field.id;
            }

            if (field.type === FIELD_TYPE_MULTIPLE) {
                field.records.forEach((nestedField) => {
                    this.addUniqueKeys(nestedField, sortKeys);
                });
            } else if (field.type === FIELD_TYPE_SINGLE) {
                if (field.annotationComponents && field.annotationComponents.length > 0) {
                    field.annotationComponents.forEach(component => {
                        if (component.startOffset >= 0) {
                            sortKeys.push(this.sortKeyForComponent(component));
                        }
                    });
                } else {
                    if (index <= 0) {
                        // Ensure that empty head fields sort first
                        sortKeys.push("0");
                    }
                }
            }
        });

        return firstId;
    }

    annotateField(annotation, isCtrlPressed) {
        const field = this.cache[this.currentFieldId];

        let changed = false;

        if (field) {
            if (isCtrlPressed) {
                field.annotationComponents.push(annotation);
                field.userText = (field.userText + " " + annotation.text).trim();
            } else {
                field.annotationComponents = [ annotation ];
                field.userText = annotation.text;
                this.moveToNextField();
            }

            changed = true;
        }

        return changed;
    }

    changeFieldValue(value) {
        let changed = false;
        const field = this.currentField();

        if (field) {
            field.userText = value;
            changed = true;
        }

        return changed;
    }

    cloneNestedGroup(multipleField) {
        let nestedGroup = cloneObject(multipleField.records[0]);

        nestedGroup.id = uniqueId();
        // console.log("cloneNestedGroup nestedGroup.id", nestedGroup.id);
        this.cache[nestedGroup.id] = nestedGroup;

        this.prepareNestedGroup(nestedGroup);
        multipleField.records.push(nestedGroup);
    }

    compareKeys(keyA, keyB) {
        const aSplit = keyA.split(":");
        const bSplit = keyB.split(":");
        let compare = Number(aSplit[0]) - Number(bSplit[0]);

        if (compare !== 0) {
            return compare;
        }

        compare = Number(aSplit[1]) - Number(bSplit[1]);

        return compare;
    }

    compareRecords(a, b) {
        const maxLen = Math.min(a.sortKeys.length, b.sortKeys.length);
        let i = 0;

        while (i < maxLen) {
            const compare = this.compareKeys(a.sortKeys[i], b.sortKeys[i]);

            if (compare !== 0) {
                return compare;
            }

            i += 1;
        }

        // The reversal of a - b is intentional here.
        return b.sortKeys.length - a.sortKeys.length;
    }

    currentField() {
        return this.cache[this.currentFieldId];
    }

    currentFieldIsInRecord(record) {
        const self = this;
        let foundCurrentField = false;

        record.forEach((field) => {
            if (field.id === self.currentFieldId) {
                foundCurrentField = true;
            } else if (field.type === FIELD_TYPE_MULTIPLE) {
                if (!foundCurrentField) {
                    field.records.forEach((nestedRecord) => {
                        foundCurrentField = foundCurrentField || self.currentFieldIsInRecord(nestedRecord);
                    });
                }
            }
        });

        return foundCurrentField;
    }

    currentMultipleField() {
        const self = this;
        let targetGroup;

        each("#f" + this.currentFieldId, function (element) {
            const targetIndex = Number(element.getAttribute("recordindex"));

            targetGroup = self.targetRecordGroup(self.annotations.records[targetIndex],
                                                 null,
                                                 self.currentFieldId);
        });

        return targetGroup;
    }

    deleteAnnotation() {
        let changed = false;
        const field = this.currentField();

        if (field) {
            field.annotationComponents = [];
            field.userText = "";

            changed = true;
        }

        return changed;
    }

    deleteRecord(recordIndex) {
        let changed = false;

        if (recordIndex >= 0 && recordIndex < this.annotations.records.length) {
            if (this.currentFieldIsInRecord(this.annotations.records[recordIndex])) {
                this.setCurrentField(null, null);
            }

            this.uncacheRecord(this.annotations.records[recordIndex]);
            this.annotations.records.splice(recordIndex, 1);

            if (this.annotations.records.length <= 0) {
                this.addNewRecord(this.annotations.records);
            }

            changed = true;
        }

        this.dumpCache("deleteRecord");

        return changed;
    }

    generateFieldListForRecords(fields, records) {
        const self = this;

        if (records === undefined) {
            if (this.annotations === undefined) {
                return;
            }

            records = this.annotations.records;
        }

        records.forEach((record) => {
            record.forEach((field) => {
                if (field.type === FIELD_TYPE_SINGLE) {
                    fields.push(field);
                } else {
                    self.generateFieldListForRecords(fields, field.records);
                }
            });
        });
    }

    insertNewRecord(offsetFromCurrent) {
        let changed = false;

        if (this.currentFieldId) {
            let index = offsetFromCurrent;

            each("#f" + this.currentFieldId, function (element) {
                const currentIndex = Number(element.getAttribute("recordindex"));
                index += currentIndex;
            });

            this.currentFieldId = this.addNewRecord(this.annotations.records, index);
            changed = true;
        }

        this.dumpCache("insertNewRecord");

        return changed;
    }

    loadAnnotations(annotationsJson) {
        // console.log("loadAnnotations is resetting ID's");
        this.annotations = annotationsJson;

        if (this.annotations && this.annotations.records) {
            this.annotations.records.forEach((record) => {
                this.addUniqueKeys(record);
                // Preserve the position of the first (head) field, sort the rest
                record.sortKeys = record.sortKeys.slice(0, 1).concat(record.sortKeys.slice(1).sort(this.compareKeys));
                this.sortRecord(record);
            });

            this.annotations.records.sort(this.compareRecords);
        }

        this.dumpCache("loadAnnotations");
    }

    moveToNextField() {
        const fields = [];

        this.generateFieldListForRecords(fields);

        if (this.currentFieldId) {
            let i = 0;

            while (i < fields.length) {
                if (fields[i].id === this.currentFieldId) {
                    if (i + 1 >= fields.length) {
                        this.insertNewRecord(INSERT_AFTER);

                        let newFields = [];

                        this.generateFieldListForRecords(newFields);
                        this.setCurrentField(newFields[i + 1].id, newFields[i + 1].label);
                    } else {
                        this.setCurrentField(fields[i + 1].id, fields[i + 1].label);
                    }

                    return;
                }

                i += 1;
            }
        }

        if (fields[0]) {
            this.setCurrentField(fields[0].id, fields[0].label);
        }
    }

    moveToNextFieldExceptLast() {
        const fields = [];

        this.generateFieldListForRecords(fields);

        if (this.currentFieldId) {
            let i = 0;

            while (i < fields.length) {
                if (fields[i].id === this.currentFieldId) {
                    if (i < fields.length - 1) {
                        this.setCurrentField(fields[i + 1].id, fields[i + 1].label);
                    }

                    return;
                }

                i += 1;
            }
        }

        this.setCurrentField(fields[0].id, fields[0].label);
    }

    moveToPreviousField() {
        const fields = [];

        this.generateFieldListForRecords(fields);

        if (this.currentFieldId) {
            let i = 0;

            while (i < fields.length) {
                if (fields[i].id === this.currentFieldId) {
                    if (i > 0) {
                        this.setCurrentField(fields[i - 1].id, fields[i - 1].label);
                    }

                    return;
                }

                i += 1;
            }
        }

        this.setCurrentField(fields[0].id, fields[0].label);
    }

    prepare(formName, formJson) {
        this.annotations = {
            formName,
            primaryObj: formJson.primaryObj,
            records: [],
        };

        this.prepareRecord(formJson);
        this.addNewRecord(this.annotations.records);

        this.dumpCache("prepare");
    }

    prepareField(field) {
        if (field.type === FIELD_TYPE_SINGLE) {
            return {
                type: FIELD_TYPE_SINGLE,
                label: field.label,
                lexical: true,
                hint: field.hint,
                userText: "",
                annotationComponents: [],
            };
        } else {
            let multipleFields = [];

            field.fields.forEach((nestedField) => {
                multipleFields.push(this.prepareField(nestedField));
            });

            return {
                type: FIELD_TYPE_MULTIPLE,
                hint: field.hint,
                records: [ multipleFields ],
            };
        }
    }

    prepareNestedGroup(field) {
        field.forEach((nestedField) => {
            nestedField.id = uniqueId();
            // console.log("prepareNestedGroup nestedField.id", nestedField.id);
            this.cache[nestedField.id] = nestedField;

            if (nestedField.type === FIELD_TYPE_SINGLE) {
                nestedField.userText = "";
                nestedField.annotationComponents = [];
                delete nestedField.colorIndex;
            } else {
                let i = 0;

                while (i < nestedField.records.length) {
                    this.uncacheRecord(nestedField.records[i]);
                    i += 1;
                }

                nestedField.records.splice(1);
                this.prepareNestedGroup(nestedField.records[0]);
            }
        });

        this.dumpCache("prepareNestedGroup");
    }

    prepareRecord(formJson) {
        let index = 0;

        this.recordTemplate = [];
        this.annotationsTemplate = {
            formName: formJson.formName,
            primaryObj: formJson.primaryObj,
            records: [],
        };

        if (formJson && formJson.fields) {
            formJson.fields.forEach((field) => {
                this.recordTemplate.push(this.prepareField(field, index));
                index += 1;
            });
        }

        this.addNewRecord(this.annotationsTemplate.records);
        this.dumpCache("prepareRecord");
    }

    rebuildCache(record) {
        if (typeof record === "undefined") {
            this.dumpCache(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> rebuildCache started");
            this.cache = {};

            if (this.annotations) {
                this.annotations.records.forEach((nestedRecord) => {
                    this.rebuildCache(nestedRecord);
                });
            }
            this.dumpCache("rebuildCache complete <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
        } else {
            if (record.id) {
                this.cache[record.id] = record;
            } else {
                console.log(">>>>>>>>>>>> record ID missing", record);
            }

            record.forEach((field) => {
                if (field.id) {
                    this.cache[field.id] = field;
                } else {
                    console.log(">>>>>>>>>>>> field ID missing", record);
                }

                if (field.type === FIELD_TYPE_MULTIPLE) {
                    field.records.forEach((nestedField) => {
                        this.rebuildCache(nestedField);
                    });
                }
            });
        }
    }

    removeLastNestedRecord() {
        let changed = false;

        if (this.currentFieldId) {
            const multipleField = this.currentMultipleField();

            if (multipleField && multipleField.records && multipleField.records.length > 1) {
                this.uncacheRecord(multipleField.records[multipleField.records.length - 1]);
                multipleField.records.splice(-1);

                const currentField = this.currentField();

                if (typeof currentField === "undefined") {
                    this.setCurrentField(null, null);
                }

                changed = true;
            }
        }

        return changed;
    }

    setCurrentField(fieldId, fieldName) {
        this.currentFieldId = fieldId;
        this.currentFieldName = fieldName;
    }

    sortKeyForComponent(component) {
        const offset = component.startOffset;
        let page = 0;

        if (component.resource !== undefined && component.resource.uri !== undefined) {
            const resourcePage = /^.*[/](.*)[/]pdf$/.exec(component.resource.uri)[1];

            if (resourcePage !== undefined && resourcePage !== "") {
                page = resourcePage;
            }
        }

        return `${page}:${offset}`;
    }

    sortRecord(record) {
        // console.log('record', record);
        record.forEach((field) => {
            if (field.records !== undefined) {
                // console.log('field', field);
                field.records.forEach((subrecord) => {
                    // console.log('subrecord', subrecord);
                    this.addUniqueKeys(subrecord);
                    subrecord.sortKeys.sort(this.compareKeys);
                    this.sortRecord(subrecord);
                });

                field.records.sort(this.compareRecords);
            }
        });
    }

    targetRecordGroup(record, parentRecord, fieldId) {
        let i = 0;

        while (i < record.length) {
            if (record[i].id === fieldId) {
                return parentRecord;
            } else if (record[i].type === FIELD_TYPE_MULTIPLE) {
                let j = 0;

                while (j < record[i].records.length) {
                    let nestedRecord = this.targetRecordGroup(record[i].records[j], record[i], fieldId);

                    if (nestedRecord) {
                        return nestedRecord;
                    }

                    j += 1;
                }
            }

            i += 1;
        }
    }

    uncacheRecord(record) {
        delete this.cache[record.id];

        if (record.type === FIELD_TYPE_MULTIPLE) {
            record.records.forEach((field) => {
                this.uncacheRecord(field);
            });
        } else if (record.type !== FIELD_TYPE_SINGLE) {
            record.forEach((field) => {
                this.uncacheRecord(field);
            });
        }
    }
}

export default AnnotatedForm;
