import React from 'react';

function FormRecordDelete(props) {
    const { onDeleteRecord, onHover, personaName, recordId, recordIndex } = props;
    const modeGreenQQ = personaName === "GreenQQ";

    return (
        <button
            title={`${modeGreenQQ ? "Clear" : "Delete"} Record`}
            className="grey-text text-lighten-1 btn-flat RecordDelete"
            onClick={(e) => { e.preventDefault(); onDeleteRecord(recordIndex); }}
            onMouseEnter={() => onHover(true, recordId)}
            onMouseLeave={() => onHover(false, recordId)}
        >
            <i className="material-icons">{modeGreenQQ ? "autorenew" : "delete_forever"}</i>
        </button>
    );
}

export default FormRecordDelete;
