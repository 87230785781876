import React from 'react';
import Comet from '../CometEngine/Comet';
import GreenQQ from '../GreenQQ/GreenQQ';
import AdminPage from '../AdminPage/AdminPage';
import LoginPage from '../AdminPage/LoginPage';
import {ConfigProvider, useConfig} from './Config';

function Container(props) {
    const { config } = useConfig();

    if (typeof config.session !== "string" || config.session === "") {
        return <LoginPage />;
    }

    if (config.step === "GreenQQ") {
        return <GreenQQ {...props} />;
    }

    if (config.step === "admin" || Number(config.pageNumber) <= 0) {
        return <AdminPage {...props} />;
    }

    return <Comet {...props} />;
}

function App(props) {
    return (
        <React.StrictMode>
            <ConfigProvider>
                <Container {...props} />
            </ConfigProvider>
        </React.StrictMode>
    );
}

export default App;