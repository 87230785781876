import React, { useEffect, useState } from 'react';
import { camelCaseToSpaces, uriForList } from '../App/Utility';
import { useConfig } from '../App/Config';
import EditPageForm from './EditPageForm';
import PersonaForm from './PersonaForm';
import MergedPersonaForm from './MergedPersonaForm';
import GreenQQForm from './GreenQQForm';
import AnnotationsListForm from './AnnotationsListForm';
import './AdminPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';

function AdminPage(props) {
    const { config } = useConfig();
    const [mergedPersonas, setMergedPersonas] = useState();
    const [personas, setPersonas] = useState();
    const [tree, setTree] = useState();

    useEffect(() => {
        // Allow admin page to scroll
        document.querySelector("body").style.overflow = "auto";
    }, []);

    useEffect(() => {
        const payload = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, */*',
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ session: config.session }),
        };

        fetch(uriForList(), payload)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.success) {
                        setMergedPersonas(result.mergedPersonas);
                        setPersonas(result.personas);
                        setTree(result.tree);
                    } else {
                        M.toast({ html: "<span>Unable to retrieve list</span>" });
                    }
                },
                (error) => {
                    M.toast({ html: "<span>Error requesting list: " + error + "</span>" });
                }
            );
    }, [config.session]);

    function generateBooksSelect(clickHandler) {
        let books = [<option value="" disabled key="choosebook">Choose a book</option>];

        config.meta.forEach((bookObject) => {
            const title = camelCaseToSpaces(bookObject.shortTitle);

            books.push(<option
                            value={bookObject.shortTitle}
                            key={bookObject.shortTitle}
                            onClick={clickHandler}>{title}</option>);
        });

        return books;
    }

    function receiveFocus(event) {
        if (event && event.target && event.target.select) {
            event.target.select();
        }
    }

    return(
        <div className="adminPage">
            <nav className="LoginHeader blue lighten-5">
                <span className="brand-logo blue-grey-text text-darken-4"
                        title="Click-Only (or at least Mostly) Extraction Tool">&nbsp; COMET
                </span>
            </nav>
            <div className="container AdminMenu">
                <EditPageForm
                    {...props}
                    generateBooksSelect={generateBooksSelect}
                    receiveFocus={receiveFocus}
                />
                <PersonaForm
                    {...props}
                    generateBooksSelect={generateBooksSelect}
                    personas={personas}
                    receiveFocus={receiveFocus}
                />
                <MergedPersonaForm
                    {...props}
                    generateBooksSelect={generateBooksSelect}
                    mergedPersonas={mergedPersonas}
                    receiveFocus={receiveFocus}
                />
            </div>
            <AnnotationsListForm
                {...props}
                tree={tree}
            />
            <GreenQQForm
                {...props}
                generateBooksSelect={generateBooksSelect}
                receiveFocus={receiveFocus}
            />
        </div>
    );
}

export default AdminPage;
