import { useEffect, useRef } from 'react';
import { ANNOTATION_BASE_URI, BASE_URI, FIELD_TYPE_MULTIPLE, HINT_TYPE_INDENT } from '../Constants';
import hex_md5 from './md5-min.js';

function addClass(element, className) {
    if (element.classList) {
        element.classList.add(className);
    } else {
        element.className += " " + className;
    }
}

function annotationUriForPage(book, maxPage, pageNumber, type) {
    return `${ANNOTATION_BASE_URI}books/${book}/${formattedPageNumber(pageNumber, maxPage)}/${type}`;
}

function camelCaseToSpaces(value) {
    if (typeof(value) === "undefined") {
        return value;
    }

    let i = value.length - 1;

    while (i > 0) {
        const c = value.charAt(i);

        if (c === c.toUpperCase()) {
            value = value.slice(0, i) + " " + value.slice(i, value.length);
        }

        --i;
    }

    return value;
}

function cloneObject(obj) {
    let clone;

    if (obj === null) {
        clone = null;
    } else if (Array.isArray(obj)) {
        clone = obj.map(element => cloneObject(element));

        if (obj.id !== null && typeof obj.id === "string") {
            clone.id = obj.id;
        }
    } else {
        clone = {};

        Object.getOwnPropertyNames(obj).forEach(key => {
            if (obj[key] !== null && typeof(obj[key]) === "object") {
                clone[key] = cloneObject(obj[key]);
            } else {
                clone[key] = obj[key];
            }
        });
    }

    return clone;
}

// See https://stackoverflow.com/a/4049020
function dateFromYearDay(year, day) {
    const date = new Date(year, 0);

    return new Date(date.setDate(day));
}

// See https://stackoverflow.com/a/30810322
function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");

    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (error) {
        // Silently ignore
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
    } else {
        navigator.clipboard.writeText(text).then(function() {
            // Silently ignore
        }, function(err) {
            // Silently ignore
        });
    }
}

var entities = [
    ['amp', '&'],
    ['apos', '\''],
    ['#x27', '\''],
    ['#x2F', '/'],
    ['#39', '\''],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"']
];

function decodeHTMLEntities(text) {
    entities.forEach((entity) => {
        text = text.replace(new RegExp('&' + entity[0] + ';', 'g'), entity[1]);
    });

    return text;
}

function each(selector, apply) {
    const elements = document.querySelectorAll(selector);

    Array.prototype.forEach.call(elements, apply);
}

function formattedPageNumber(pageNumber, maxPage) {
    let formattedNumber = Number(pageNumber).toString(10);
    let requiredLength = Number(maxPage).toString(10).length; 

    while (formattedNumber.length < requiredLength) {
        formattedNumber = "0" + formattedNumber;
    }

    return formattedNumber;
}

function getIndent(hint) {
    if (hint.includes(HINT_TYPE_INDENT)) {
        const regexp = new RegExp(`${HINT_TYPE_INDENT}\\s*([0-9]*)`, "i");
        const match = regexp.exec(hint);

        if (match) {
            const indent = Number(match[1]);

            if (!isNaN(indent)) {
                return indent;
            }
        }
    }

    return 0;
}

function hasClass(element, className) {
    if (element.classList) {
        return element.classList.contains(className);
    }

    return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
}

function isTextInputActive() {
    return ( document.activeElement &&
             document.activeElement.tagName.toLowerCase() === "input" &&
             document.activeElement.getAttribute("type") === "text" );
}

function removeClass(element, className) {
    if (element.classList) {
        element.classList.remove(className);
    } else {
        element.className = element.className.replace(
            new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
}

function removeKeys(record) {
    record.forEach((field) => {
        delete field.id;

        if (field.type === FIELD_TYPE_MULTIPLE) {
            field.records.forEach((nestedField) => {
                removeKeys(nestedField);
            });
        }
    });
}

// See http://bit.ly/2X2gpcS
function syntaxHighlight(json) {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }

    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
                        function (match) {
        let cls = 'number';

        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }

        return '<span class="' + cls + '">' + match + '</span>';
    });
    
    return { __html: json };
}

var fieldCount = 0;

function uniqueId() {
    var time = new Date().getTime();

    return hex_md5(time + "-" + (++fieldCount));
}

function uriForBookFormPage(book, formName, pageNumber, maxPage) {
    return `${BASE_URI}ann/${book}/${formName}/${formattedPageNumber(pageNumber, maxPage)}`;
}

function uriForFormVersion(formName, formVersion) {
    return `${BASE_URI}form/${formName}/${formVersion}`;
}

function uriForList() {
    return `${BASE_URI}list`;
}

function uriForLogin() {
    return `${BASE_URI}auth`;
}

function uriForPage(book, maxPage, pageNumber, type) {
    return `${BASE_URI}books/${book}/${formattedPageNumber(pageNumber, maxPage)}/${type}`;
}

function uriForBookMergedPersona(book, mergedPersonaName) {
    return encodeURI(`${BASE_URI}mergedpersona/${book}/${mergedPersonaName}`);
}

function uriForBookPersona(book, personaName) {
    return encodeURI(`${BASE_URI}persona/${book}/${personaName}`);
}

function uriForSave() {
    return `${BASE_URI}save`;
}

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export {
    addClass, annotationUriForPage, camelCaseToSpaces, cloneObject,
    copyTextToClipboard, dateFromYearDay, decodeHTMLEntities, each,
    formattedPageNumber, getIndent, hasClass, isTextInputActive,
    removeClass, removeKeys, syntaxHighlight, uniqueId, uriForBookFormPage,
    uriForBookMergedPersona, uriForBookPersona, uriForFormVersion,
    uriForList, uriForLogin, uriForPage, uriForSave, usePrevious,
};
