import React from 'react';
import { camelCaseToSpaces, uniqueId } from '../App/Utility';
import { useConfig } from '../App/Config';
import { BUTTON_CLASS } from '../Constants';

function AnnotationsListForm(props) {
    const { tree } = props;
    const { config, updateConfig } = useConfig();

    function loadPage(event) {
        event.preventDefault();

        const [book, formName, pageNumber] = event.target.getAttribute("data-target").split(" ");
        let bookObject = config.booksList[book];

        if (bookObject.shortTitle === book) {
            let formVersion = 2;

            if (formName === "Individual") {
                formVersion = 3;
            }

            updateConfig({
                book, bookObject, maxPage: bookObject.maxPage,
                formName, formVersion, pageNumber, step: null,
                mergedPersonaName: "", personaName: "",
            });
        }
    }

    if (!tree) {
        return null;
    }

    let rows = [];

    if (Object.keys(tree).length > 0) {
        rows.push(<div className="divider" key={uniqueId()} />);
    }

    Object.keys(tree).forEach((book) => {
        let bookShortTitle = book.slice(7);

        if (Object.keys(tree[book]).length <= 0) {
            return;
        }

        rows.push(
            <div className="row" key={uniqueId()}>
                <h6 className="col s12 bookRow">{camelCaseToSpaces(bookShortTitle)}</h6>
            </div>);

        rows.push(
            <div className="row" key={uniqueId()}>
                <span className="col s2 FormName header">Form</span>
                <span className="col s1 right-align Page header">Page</span>
                <span className="col s2 right-align Size header">File Size</span>
                <span className="col s3 right-align Mtime header">Last Modified</span>
            </div>
        );

        Object.keys(tree[book]).forEach((form) => {
            let first = true;

            Object.keys(tree[book][form]).sort().forEach((page) => {
                rows.push(
                    <div className="row" key={uniqueId()}>
                        <span className="col s2 FormName">{first ? form : ""}</span>
                        <span className="col s1 right-align Page">{page}</span>
                        <span className="col s2 right-align Size">{Number(tree[book][form][page].size).toLocaleString()}</span>
                        <span className="col s3 right-align Mtime">{new Date(tree[book][form][page].mtime).toLocaleString()}</span>
                        <span className="col s3 Edit">
                            <button
                                className={BUTTON_CLASS}
                                onClick={loadPage}
                                data-target={bookShortTitle + " " + form + " " + page}
                            >Open {page} <i className="material-icons right">send</i>
                            </button>
                        </span>
                    </div>
                );

                first = false;
            });
        });

        rows.push(<div className="divider" key={uniqueId()} />);
    });

    return (
        <div className="container AnnotationsTable">
            <div className="row">
                <h5 className="col s12">Saved annotations:</h5>
            </div>
            {rows}
        </div>
    );
}

export default AnnotationsListForm;