import React from 'react';
import './FormControls.css';

function FormControls(props) {
    const { onDone, onSave, onToggleHelp } = props;

    return (
        <div className="FormControls z-depth-2">
            <button
                className="btn waves-effect waves-light blue"
                onClick={onSave}
                >Save <i className="material-icons right">save</i>
            </button> &nbsp;
            <button
                className="btn waves-effect waves-light blue modal-trigger"
                onClick={onToggleHelp}
                >Help <i className="material-icons right">help</i>
            </button> &nbsp;
            <button
                className="btn waves-effect waves-light blue modal-trigger"
                data-target="jsonModal"
                >Show JSON <i className="material-icons right">subject</i>
            </button> &nbsp;
            <button
                className="btn waves-effect waves-light blue"
                onClick={onDone}
                >Done <i className="material-icons right">send</i>
            </button> &nbsp; &nbsp; &nbsp; &nbsp; 
            <button
                className="btn waves-effect waves-light red lighten-2 CancelButton modal-trigger"
                data-target="confirmModal"
                >Cancel <i className="material-icons right">close</i>
            </button>
            <img alt="Resize handle" src="images/resize_handle_18x18.png" />
        </div>
    );
}

export default FormControls;
