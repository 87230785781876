import React, { useState } from 'react';
import { useConfig } from '../App/Config';
import { BUTTON_CLASS } from '../Constants';

function MergedPersonaForm(props) {
    const { generateBooksSelect, mergedPersonas } = props;
    const { config, onEditMergedPersona, updateConfig } = useConfig();
    const [personaOptions, setPersonaOptions] = useState();

    let bookSelect;
    let personaSelect;
    const books = generateBooksSelect(handleBookSelection);
    let buttonClass = BUTTON_CLASS;

    if (config.mergedPersonaBook === "" || config.mergedPersonaName === "") {
        buttonClass += " disabled";
    }

    function handleBookSelection(event) {
        event.preventDefault();

        const selection = bookSelect.options[bookSelect.selectedIndex];
        let options = [<option value="" disabled key="choosemergedpersona">Choose a persona</option>];
        let mergedPersonaBookObject = config.booksList[selection.value];

        if (selection.value === mergedPersonaBookObject.shortTitle) {
            const mergedPersonaName = "";
            const maxPage = mergedPersonaBookObject.maxPage;
            const pageNumber = 0;

            if (mergedPersonas[mergedPersonaBookObject.folder]) {
                mergedPersonas[mergedPersonaBookObject.folder].forEach(mergedPersonaNameEntry => {
                    options.push(
                        <option value={mergedPersonaNameEntry} key={`m${mergedPersonaNameEntry}`}>
                            {mergedPersonaNameEntry}
                        </option>
                    );
                });
            }

            updateConfig({
                mergedPersonaBook: mergedPersonaBookObject.shortTitle,
                mergedPersonaBookObject,
                mergedPersonaName,
                maxPage,
                pageNumber
            });
        }

        setPersonaOptions(options);
    }

    function handlePersonaSelection(event) {
        event.preventDefault();

        const selection = personaSelect.options[personaSelect.selectedIndex];
        const mergedPersonaName = selection.value;
        const regexp = /osmx[0-9]*-([0-9]*)-.*/i;
        const match = regexp.exec(mergedPersonaName);

        let pageNumber = 0;

        if (match) {
            pageNumber = Number(match[1]);
        }

        updateConfig({ mergedPersonaName, pageNumber });
    }

    return (
        <form className="MergedPersonaForm" onSubmit={(e) => { e.preventDefault(); onEditMergedPersona(); }}>
            <div className="row">
                <h5 className="col s12">MergedPersonas TreeReady annotations:</h5>
            </div>
            <div className="row">
                <div className="input-field col s4">
                    <select
                        className="browser-default"
                        id="MergedPersonaBookSelect"
                        ref={(element) => { bookSelect = element; }}
                        onChange={handleBookSelection}
                        value={config.mergedPersonaBook === "" ? config.mergedPersonaBook : config.mergedPersonaBook.shortTitle}
                    >
                        {books}
                    </select>
                </div>
                <div className="input-field col s4">
                    <select
                        className="browser-default"
                        id="MergedPersonaSelect"
                        ref={(element) => { personaSelect = element; }}
                        onChange={handlePersonaSelection}
                        value={config.mergedPersonaName}
                    >
                        {personaOptions}
                    </select>
                </div>
                <div className="col s2 MergedPersona">
                    <button
                        className={buttonClass}
                        onClick={(e) => { e.preventDefault(); onEditMergedPersona(); }}
                    >Open <i className="material-icons right">send</i>
                    </button>
                </div>
            </div>
        </form>
    );
}

export default MergedPersonaForm;