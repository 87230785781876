import React, { useState } from 'react';
import { useConfig } from '../App/Config';
import { BUTTON_CLASS } from '../Constants';

function PersonaForm(props) {
    const { generateBooksSelect, personas } = props;
    const { config, onEditPersona, updateConfig } = useConfig();
    const [personaOptions, setPersonaOptions] = useState();

    let bookSelect;
    let personaSelect;
    const books = generateBooksSelect(handleBookSelection);
    let buttonClass = BUTTON_CLASS;

    if (config.personaBook === "" || config.personaName === "") {
        buttonClass += " disabled";
    }

    function handleBookSelection(event) {
        event.preventDefault();

        const selection = bookSelect.options[bookSelect.selectedIndex];
        let options = [<option value="" disabled key="choosepersona">Choose a persona</option>];
        let personaBookObject = config.booksList[selection.value];

        if (selection.value === personaBookObject.shortTitle) {
            const personaName = "";
            const maxPage = personaBookObject.maxPage;
            const pageNumber = 0;

            if (personas[personaBookObject.folder]) {
                personas[personaBookObject.folder].forEach(personaNameEntry => {
                    options.push(
                        <option value={personaNameEntry} key={personaNameEntry}>
                            {personaNameEntry}
                        </option>
                    );
                });
            }

            updateConfig({
                personaBook: personaBookObject.shortTitle,
                personaBookObject,
                personaName,
                maxPage,
                pageNumber
            });
        }

        setPersonaOptions(options);
    }

    function handlePersonaSelection(event) {
        event.preventDefault();

        const selection = personaSelect.options[personaSelect.selectedIndex];
        const personaName = selection.value;
        const regexp = /osmx[0-9]*-([0-9]*)-.*/i;
        const match = regexp.exec(personaName);

        let pageNumber = 0;

        if (match) {
            pageNumber = Number(match[1]);
        }

        updateConfig({ personaName, pageNumber });
    }

    return (
        <form className="PersonaForm" onSubmit={(e) => { e.preventDefault(); onEditPersona(); }}>
            <div className="row">
                <h5 className="col s12">TreeReady annotations:</h5>
            </div>
            <div className="row">
                <div className="input-field col s4">
                    <select
                        className="browser-default"
                        id="PersonaBookSelect"
                        ref={(element) => { bookSelect = element; }}
                        onChange={handleBookSelection}
                        value={config.personaBook === "" ? config.personaBook : config.personaBook.shortTitle}
                    >
                        {books}
                    </select>
                </div>
                <div className="input-field col s4">
                    <select
                        className="browser-default"
                        id="PersonaSelect"
                        ref={(element) => { personaSelect = element; }}
                        onChange={handlePersonaSelection}
                        value={config.personaName}
                    >
                        {personaOptions}
                    </select>
                </div>
                <div className="col s2 Persona">
                    <button
                        className={buttonClass}
                        onClick={(e) => { e.preventDefault(); onEditPersona(); }}
                    >Open <i className="material-icons right">send</i>
                    </button>
                </div>
            </div>
        </form>
    );
}

export default PersonaForm;