import React, { Component } from 'react';
import { COLOR_COUNT, FIELD_TYPE_SINGLE, FORM_WIDTH_FACTOR_NORMAL,
         FORM_WIDTH_FACTOR_WIDE, WIDE_FORMS, ZOOM_DELTA, ZOOM_FACTOR } from '../Constants.js';
import AnnotatedForm from '../CometEngine/AnnotatedForm.js';
import FormWidget from '../CometEngine/FormWidget.js';
import HeaderWidget from '../CometEngine/HeaderWidget.js';
import KeyboardListener from '../CometEngine/KeyboardListener.js';
import PageWidget from '../CometEngine/PageWidget.js';
import { annotationUriForPage, cloneObject, each, isTextInputActive,
         uriForPage, uriForFormVersion } from '../App/Utility.js';
import ResizeObserver from 'resize-observer-polyfill';
import './GreenQQ.css';

class GreenQQ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            annotatedForm: new AnnotatedForm(),
            deleteHover: null,
            formPageNumber: this.props.pageNumber,
            highlightHover: null,
            highlightRanges: null,
            isAltPressed: false,
            isCtrlPressed: false,
            isKeyboardEnabled: true,
            isShiftPressed: false,
            pageDisplayMode: 0,
            resizeObserver: null,
            zoomFactor: ZOOM_FACTOR,
        };

        this.annotationForSelection = this.annotationForSelection.bind(this);
        this.autoscrollToCurrent = this.autoscrollToCurrent.bind(this);
        this.cloneAnnotatedForm = this.cloneAnnotatedForm.bind(this);
        this.fetchResources = this.fetchResources.bind(this);
        this.fetchForm = this.fetchForm.bind(this);
        this.gatherHighlightRanges = this.gatherHighlightRanges.bind(this);
        this.gatherHighlightRangesForField = this.gatherHighlightRangesForField.bind(this);
        this.highlightRangesForRecord = this.highlightRangesForRecord.bind(this);
        this.observeResizing = this.observeResizing.bind(this);
        this.onAddNestedRecords = this.onAddNestedRecords.bind(this);
        this.onBlurActiveField = this.onBlurActiveField.bind(this);
        this.onDeleteAnnotation = this.onDeleteAnnotation.bind(this);
        this.onDeleteHover = this.onDeleteHover.bind(this);
        this.onDeleteRecord = this.onDeleteRecord.bind(this);
        this.onDone = this.onDone.bind(this);
        this.onHighlightHover = this.onHighlightHover.bind(this);
        this.onInsertNewRecord = this.onInsertNewRecord.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onMoveToNextField = this.onMoveToNextField.bind(this);
        this.onMoveToPreviousField = this.onMoveToPreviousField.bind(this);
        this.onPageNext = this.onPageNext.bind(this);
        this.onPagePrevious = this.onPagePrevious.bind(this);
        this.onPageSelect = this.onPageSelect.bind(this);
        this.onRemoveLastNestedRecord = this.onRemoveLastNestedRecord.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onZoomIn = this.onZoomIn.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);
        this.pageSettings = this.pageSettings.bind(this);
        this.removeColorIndexes = this.removeColorIndexes.bind(this);
        this.removeColorIndexesForRecord = this.removeColorIndexesForRecord.bind(this);
        this.resizePanels = this.resizePanels.bind(this);
        this.setAltState = this.setAltState.bind(this);
        this.setCtrlState = this.setCtrlState.bind(this);
        this.setCurrentField = this.setCurrentField.bind(this);
        this.setShiftState = this.setShiftState.bind(this);
    }

    componentDidMount() {
        this.fetchResources();
        window.onresize = this.resizePanels;
        this.resizePanels();
        this.observeResizing();
    }

    componentDidUpdate(prevProps, prevState) {
        this.observeResizing();

        if (prevState.annotatedForm.currentFieldId !== this.state.annotatedForm.currentFieldId) {
            this.autoscrollToCurrent();
        }

        if (this.state.annotatedForm.currentFieldId === null) {
            this.onMoveToNextField();
        }
    }

    componentWillUnmount() {
        if (this.state.resizeObserver) {
            this.state.resizeObserver.disconnect();
        }
    }

    render() {
        const { error, isLoaded } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        }

        const { book, firstPage, formName, formVersion, lastPage, maxPage,
                pageNumber, skipBottom, skipTop } = this.props;
        const { annotatedForm, deleteHover, formPageNumber, highlightHover,
                highlightRanges, isAltPressed, isCtrlPressed, isKeyboardEnabled,
                isShiftPressed, pageDisplayMode, zoomFactor } = this.state;
        const { annotations, currentFieldId, currentFieldName } = annotatedForm;

        if (currentFieldName && !currentFieldId) {
            const annotatedForm = this.cloneAnnotatedForm();

            annotatedForm.currentFieldName = null;

            this.setState({ annotatedForm });
        }

        return (
            <div className="GreenQQ">
                <KeyboardListener
                    isAltPressed={isAltPressed}
                    isCtrlPressed={isCtrlPressed}
                    isKeyboardEnabled={isKeyboardEnabled}
                    isShiftPressed={isShiftPressed}
                    onAddNestedRecords={this.onAddNestedRecords}
                    onBlurActiveField={this.onBlurActiveField}
                    onDeleteAnnotation={this.onDeleteAnnotation}
                    onInsertNewRecord={this.onInsertNewRecord}
                    onMoveToNextField={this.onMoveToNextField}
                    onMoveToPreviousField={this.onMoveToPreviousField}
                    onRemoveLastNestedRecord={this.onRemoveLastNestedRecord}
                    setAltState={this.setAltState}
                    setCtrlState={this.setCtrlState}
                    setShiftState={this.setShiftState}
                />
                <HeaderWidget
                    book={book}
                    firstPage={firstPage}
                    formName={formName}
                    formPageNumber={formPageNumber}
                    lastPage={lastPage}
                    maxPage={maxPage}
                    onPageNext={this.onPageNext}
                    onPagePrevious={this.onPagePrevious}
                    onPageSelect={this.onPageSelect}
                    onPageSettings={this.pageSettings}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    pageDisplayMode={pageDisplayMode}
                    pageNumber={pageNumber}
                    personaName="GreenQQ"
                    skipBottom={skipBottom}
                    skipTop={skipTop}
                />
                <div className="GreenQQBody">
                    <FormWidget
                        annotations={annotations}
                        currentFieldId={currentFieldId}
                        currentFieldName={currentFieldName}
                        deleteHover={deleteHover}
                        highlightHover={highlightHover}
                        formName={formName}
                        formVersion={formVersion}
                        onCancel={this.props.onCancel}
                        onDeleteHover={this.onDeleteHover}
                        onDeleteRecord={this.onDeleteRecord}
                        onDone={this.onDone}
                        onHighlightHover={this.onHighlightHover}
                        onLoad={this.onLoad}
                        onSave={this.onSave}
                        onValueChanged={this.onValueChanged}
                        personaName="GreenQQ"
                        setCurrentField={this.setCurrentField}
                    />
                    <PageWidget
                        book={book}
                        highlightRanges={highlightRanges}
                        isAltPressed={isAltPressed}
                        maxPage={maxPage}
                        onLoad={this.onLoad}
                        onSelection={this.onSelection}
                        pageDisplayMode={pageDisplayMode}
                        pageNumber={pageNumber}
                        zoomFactor={zoomFactor}
                    />
                </div>
            </div>
        );
    }

    annotationForSelection(selection) {
        const { book, maxPage, pageNumber } = this.props;

        return {
            originalText: selection.originalText,
            startOffset: Number(selection.startOffset),
            endOffset: Number(selection.endOffset),
            resource: {
                mime: "application/pdf",
                uri: annotationUriForPage(book, maxPage, pageNumber, "pdf"),
            },
            text: selection.text,
        };
    }

    autoscrollToCurrent() {
    }

    cloneAnnotatedForm() {
        const { annotatedForm } = this.state;

        if (annotatedForm) {
            const newForm = new AnnotatedForm(annotatedForm);

            newForm.annotations = cloneObject(annotatedForm.annotations);
            newForm.rebuildCache();

            return newForm;
        } else {
            return null;
        }
    }

    fetchResources() {
        const { book, firstPage, lastPage, maxPage } = this.props;

        let urls = [];
        let page = firstPage;

        while (page <= lastPage) {
            urls.push(uriForPage(book, maxPage, page, "txt"));
            page += 1;
        }

        if (urls.length > 0) {
            Promise.all(urls.map(url => fetch(url).then(response => response.text())
            )).then(pages => {
                this.setState({ pages });
                this.fetchForm();
            })
        }
    }

    fetchForm() {
        const annotatedForm = this.cloneAnnotatedForm();
        const { formName, formVersion } = this.props;
        const uri = uriForFormVersion(formName, formVersion);

        fetch(uri)
            .then(res => res.json())
            .then(
                (formJson) => {
                    annotatedForm.prepare(formName, formJson);

                    this.setState({
                        isLoaded: true,
                        annotatedForm,
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    gatherHighlightRanges(ranges, fieldArray, colorIndex) {
        let i = 0;

        while (i < fieldArray.length) {
            if (fieldArray[i].type === FIELD_TYPE_SINGLE) {
                if ( fieldArray[i].annotationComponents &&
                     fieldArray[i].annotationComponents.length > 0 ) {

                    this.gatherHighlightRangesForField(ranges, fieldArray[i], colorIndex);
                    fieldArray[i].colorIndex = colorIndex;
                    colorIndex += 1;
                    colorIndex = colorIndex % COLOR_COUNT;
                } else {
                    delete fieldArray[i].colorIndex;
                }
            } else {
                let j = 0;

                while (j < fieldArray[i].records.length) {
                    colorIndex = this.gatherHighlightRanges(ranges, fieldArray[i].records[j], colorIndex);
                    j += 1;
                }
            }

            i += 1;
        }

        return colorIndex;
    }

    gatherHighlightRangesForField(ranges, field, colorIndex) {
        const { book, maxPage, pageNumber } = this.props;
        const pageUri = annotationUriForPage(book, maxPage, pageNumber, "pdf");
        const altPageUri = pageUri.replace("https", "http");

        field.annotationComponents.forEach((component) => {
            if (component.resource.uri === pageUri || component.resource.uri === altPageUri) {
                ranges.push([ Number(component.startOffset),
                              Number(component.endOffset) + 1,
                              colorIndex ]);
            }
        });
    }

    highlightRangesForRecord(annotatedForm, recordId) {
        const records = annotatedForm.annotations.records;
        let ranges = [];

        records.forEach((record) => {
            if (record.id === recordId) {
                this.gatherHighlightRanges(ranges, record, 0);
            }
        });

        return ranges;
    }

    observeResizing() {
        if (this.state.resizeObserver !== null || typeof ResizeObserver === "undefined") {
            return;
        }

        const formWidget = document.querySelector(".FormWidget");
        const pageWidget = document.querySelector(".PageWidget");

        if (formWidget === null || pageWidget === null) {
            return;
        }

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                pageWidget.style.width = (window.innerWidth - entry.target.offsetWidth) + "px";

                const labelContainer = document.querySelector(".FormLabels.showFixed");
                
                if (labelContainer && labelContainer.style) {
                    labelContainer.style.width = entry.target.offsetWidth + "px";
                }
            }
        });

        resizeObserver.observe(document.querySelector('.FormWidget'));

        this.setState({
            resizeObserver: resizeObserver,
        });
    }

    onAddNestedRecords(count) {
        const annotatedForm = this.cloneAnnotatedForm();
        const addedCount = annotatedForm.addNestedRecords(count);

        if (addedCount > 0) {
            this.setState({ annotatedForm });
        }
    }

    onBlurActiveField() {
        if (isTextInputActive()) {
            document.activeElement.blur();
        }
    }

    onDeleteAnnotation() {
        const annotatedForm = this.cloneAnnotatedForm();

        if (annotatedForm.deleteAnnotation()) {
            this.setState({ annotatedForm });
        }
    }

    onDeleteHover(isHovering, recordId) {
        this.setState({deleteHover: isHovering ? recordId.slice(1) : null});
    }

    onDeleteRecord(recordIndex) {
        const annotatedForm = this.cloneAnnotatedForm();

        if (annotatedForm.deleteRecord(recordIndex)) {
            this.setState({
                annotatedForm,
                highlightRanges: [],
            });
        }
    }

    onDone() {
    }

    onHighlightHover(isHovering, recordId) {
        const annotatedForm = this.cloneAnnotatedForm();
        let highlightRanges = [];

        if (isHovering) {
            this.removeColorIndexesForRecord(annotatedForm);
            highlightRanges = this.highlightRangesForRecord(annotatedForm, recordId);
        } else {
            this.removeColorIndexesForRecord(annotatedForm, recordId);
        }

        this.setState({
            annotatedForm,
            deleteHover: null,
            highlightHover: isHovering ? recordId : null,
            highlightRanges: highlightRanges,
        });
    }

    onInsertNewRecord(offsetFromCurrent) {
    }

    onLoad(formJson) {
        const annotatedForm = this.cloneAnnotatedForm();

        this.resizePanels();
        this.observeResizing();

        if (formJson) {
            annotatedForm.prepareRecord(formJson);

            this.setState({ annotatedForm });
        }
    }

    onMoveToNextField() {
        if (this.state.annotatedForm && this.state.annotatedForm.annotations) {
            const annotatedForm = this.cloneAnnotatedForm();

            annotatedForm.moveToNextFieldExceptLast();

            this.setState({ annotatedForm });
        }
    }

    onMoveToPreviousField() {
        const annotatedForm = this.cloneAnnotatedForm();

        annotatedForm.moveToPreviousField();

        this.setState({ annotatedForm });
    }

    onPageNext() {
        this.props.onPageNext();
        this.setState({highlightRanges: []});
    }

    onPagePrevious() {
        this.props.onPagePrevious();
        this.setState({highlightRanges: []});
    }

    onPageSelect(pageNumber) {
        this.props.onPageSelect(pageNumber);
        this.setState({highlightRanges: []});
    }

    onRemoveLastNestedRecord() {
        const annotatedForm = this.cloneAnnotatedForm();

        if (annotatedForm.removeLastNestedRecord()) {
            this.setState({ annotatedForm });
        }
    }

    onSave() {
        // NEEDSWORK: save the GreenQQ meta-information
    }

    onSelection(selection) {
        const annotatedForm = this.cloneAnnotatedForm();
        const { isCtrlPressed } = this.state;
        const annotation = this.annotationForSelection(selection);

        if (annotatedForm.annotateField(annotation, isCtrlPressed)) {
            this.setState({ annotatedForm });
        }
    }

    onValueChanged(value) {
    }

    onZoomIn() {
        this.setState((state, props) => ({
            zoomFactor: state.zoomFactor + ZOOM_DELTA,
        }));
    }

    onZoomOut() {
        this.setState((state, props) => ({
            zoomFactor: state.zoomFactor - ZOOM_DELTA,
        }));
    }

    pageSettings(mode) {
        this.setState((state, props) => ({
            pageDisplayMode: mode,
        }));
    }

    removeColorIndexes(fieldArray) {
        let i = 0;

        while (i < fieldArray.length) {
            if (fieldArray[i].type === FIELD_TYPE_SINGLE) {
                delete fieldArray[i].colorIndex;
            } else {
                fieldArray[i].records.forEach((nestedGroup) => {
                    this.removeColorIndexes(nestedGroup);
                });
            }

            i += 1;
        }
    }

    removeColorIndexesForRecord(annotatedForm, recordId) {
        const records = annotatedForm.annotations.records;

        records.forEach((record) => {
            if (!recordId || record.id === recordId) {
                this.removeColorIndexes(record);
            }
        });
    }

    resizePanels() {
        const { formName } = this.props;

        let resizeFactor = WIDE_FORMS.includes(formName)
                ? FORM_WIDTH_FACTOR_WIDE
                : FORM_WIDTH_FACTOR_NORMAL;
        let height = window.innerHeight;
        let width = window.innerWidth;
        let halfWidth = Math.ceil(width * resizeFactor);
        let usedWidth = 0;

        each(".HeaderWidget", (element) => {
            height -= element.offsetHeight + 4;
        });

        each(".FormWidget, .PageWidget", function (element) {
            element.style.height = height + "px";
            element.style.width = halfWidth + "px";

            usedWidth += halfWidth;
            halfWidth = width - usedWidth;
        });
    }

    setAltState(newState) {
        this.setState({isAltPressed: newState});
    }

    setCtrlState(newState) {
        this.setState({isCtrlPressed: newState});
    }

    setCurrentField(fieldId, fieldName) {
        const self = this;
        const annotatedForm = this.cloneAnnotatedForm();

        this.setState((state) => {
            if (state.annotatedForm.currentFieldId !== fieldId) {
                self.onBlurActiveField();
                annotatedForm.setCurrentField(fieldId, fieldName);

                return { annotatedForm };
            }
        });
    }

    setShiftState(newState) {
        this.setState({isShiftPressed: newState});
    }
}

export default GreenQQ;
