import { useEffect } from 'react';
import { INSERT_AFTER, INSERT_BEFORE, KEY_CODE } from '../Constants';
import { isTextInputActive } from '../App/Utility';

function clearKeypress(e) {
    e.cancelBubble = true;
    e.returnValue = false;

    if (e.preventDefault) {
        e.preventDefault();
    }

    if (e.stopPropagation) {
        e.stopPropagation();
    }

    return false;
}

function KeyboardListener(props) {
    const {
        isKeyboardEnabled,
        isShiftPressed,
        onAddNestedRecords,
        onBlurActiveField,
        onDeleteAnnotation,
        onInsertNewRecord,
        onMoveToNextField,
        onMoveToPreviousField,
        onRemoveLastNestedRecord,
        setAltState,
        setCtrlState,
        setShiftState,
    } = props;

    useEffect(() => {
        function keyA(e) {
            onInsertNewRecord(isShiftPressed ? INSERT_BEFORE : INSERT_AFTER);

            return clearKeypress(e);
        }

        function keyEsc(e) {
            onBlurActiveField();

            return clearKeypress(e);
        }

        function keyMinus(e) {
            onRemoveLastNestedRecord();

            return clearKeypress(e);
        }

        function keyNextField(e) {
            onMoveToNextField();
        }

        function keyNum(e, keyCode) {
            onAddNestedRecords(keyCode - KEY_CODE.digit0);

            return clearKeypress(e);
        }

        function keyTab(e) {
            if (isShiftPressed) {
                onMoveToPreviousField();
            } else {
                onMoveToNextField();
            }

            return clearKeypress(e);
        }

        function keyX(e) {
            onDeleteAnnotation();

            return clearKeypress(e);
        }

        function processKeydown(e) {
            if (!isKeyboardEnabled) {
                return;
            }

            if (e.keyCode === KEY_CODE.tab || e.keyCode === KEY_CODE.backspace) {
                if (!isTextInputActive()) {
                    e.preventDefault();
                }
            } else if (e.keyCode === KEY_CODE.shift && !isShiftPressed) {
                setShiftState(true);
            } else if (e.keyCode === KEY_CODE.ctrlLeft || e.keyCode === KEY_CODE.ctrlRight) {
                setCtrlState(true);
            } else if (e.keyCode === KEY_CODE.alt) {
                setAltState(true);
            }
        }

        function processKeypress(e) {
            if (e.which === KEY_CODE.tab) {
                e.preventDefault();
            }

            if (!isKeyboardEnabled) {
                return;
            }

            if (e.keyCode === KEY_CODE.shift) {
                setShiftState(false);
            } else if (e.keyCode === KEY_CODE.ctrlLeft || e.keyCode === KEY_CODE.ctrlRight) {
                setCtrlState(false);
                keyNextField(e);
            } else if (e.keyCode === KEY_CODE.alt) {
                setAltState(false);
            }

            if (isTextInputActive()) {
                if (e.keyCode === KEY_CODE.esc) {
                    keyEsc(e);
                }

                return true;
            }

            if (!e) {
                e = window.event;
            }

            if (e.keyCode === KEY_CODE.capA || e.keyCode === KEY_CODE.smallA) {
                return keyA(e);
            }

            if (e.keyCode === KEY_CODE.capX || e.keyCode === KEY_CODE.smallX ||
                e.keyCode === KEY_CODE.del || e.keyCode === KEY_CODE.backspace) {

                return keyX(e);
            }

            if (e.keyCode === KEY_CODE.tab) {
                return keyTab(e);
            }

            if (e.keyCode >= KEY_CODE.digit1 && e.keyCode <= KEY_CODE.digit9) {
                return keyNum(e, e.keyCode);
            }

            if (e.keyCode === KEY_CODE.dash || e.keyCode === KEY_CODE.minus) {
                return keyMinus(e);
            }
        }

        if (document.addEventListener) {
            document.addEventListener("keyup", processKeypress, true);
            document.addEventListener("keydown", processKeydown, true);
        } else {
            document.onkeyup = processKeypress;
            document.onkeydown = processKeydown;
        }
    
        return () => {
            if (document.removeEventListener) {
                document.removeEventListener("keyup", processKeypress, true);
                document.removeEventListener("keydown", processKeydown, true);
            } else {
                document.onkeyup = null;
                document.onkeydown = null;
            }
        };
    }, [isKeyboardEnabled, isShiftPressed, onAddNestedRecords, onBlurActiveField,
        onDeleteAnnotation, onInsertNewRecord, onMoveToNextField, onMoveToPreviousField,
        onRemoveLastNestedRecord, setAltState, setCtrlState, setShiftState]);

    return null;
}

export default KeyboardListener;
